import React, { useEffect } from 'react'

import ImgSuccess from 'assets/imgs/blackcard/success.svg'
import ImgError from 'assets/imgs/blackcard/error.svg'
import { useTranslation } from 'react-i18next'

import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'
import { useNavigate } from '@reach/router'

import * as S from './RestaurantsRating.styles'
import * as T from './RestaurantsRating.tags'

interface RatingProps {
  restaurant: string
  success: boolean
}

const RatingResponse = ({ restaurant, success }: RatingProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' })

    if (success) {
      T.handleLoadSuccessTag({ restaurant })
    } else {
      T.handleLoadErrorTag({ restaurant })
    }
  }, [])

  return (
    <S.ResponseWrapper>
      <S.ResponseHeader>
        <Text variant="headline-h3" semiBold>
          {success ? t('restaurantsRating.reviewDone') : t('restaurantsRating.reviewFailed')}
        </Text>
      </S.ResponseHeader>
      <S.ResponseContainer>
        <S.ResponseImg>
          <img src={success ? ImgSuccess : ImgError} alt="" />
        </S.ResponseImg>
        <S.ResponseText>
          <Text variant="headline-h1" semiBold>
            {success ? t('restaurantsRating.thanksForReview') : t('restaurantsRating.notRated')}
          </Text>
          <Text variant="body-3">
            {success
              ? t('restaurantsRating.reviewMessage')
              : t('restaurantsRating.troubleSendingReview')}
          </Text>
        </S.ResponseText>
      </S.ResponseContainer>
      <S.ResponseButtons>
        {success ? (
          <Button onClick={() => navigate('/')}>{t('restaurantsRating.backToDuoGourmet')}</Button>
        ) : (
          <>
            <Button onClick={() => navigate(-1)}>{t('restaurantsRating.tryAgain')}</Button>
            <Button variant="secondary" onClick={() => navigate('/')}>
              {t('restaurantsRating.backToDuoGourmet')}
            </Button>
          </>
        )}
      </S.ResponseButtons>
    </S.ResponseWrapper>
  )
}

export default RatingResponse
