import React from 'react'

import { bridgeNative } from 'App'
import { SpaceBetween } from 'App.styles'
import AppBar from 'components/AppBar'
import useAsync from 'hooks/UseAsync'
import { CancelSubscriptionResponse } from 'types/SubscriptionCancellation'
import Error from 'components/Error'
import DynamicIcon from 'components/DynamicIcon'
import { useConfigs } from 'contexts/ConfigsContext'
import TotalSaved from 'components/TotalSaved'
import { useTranslation } from 'react-i18next'

import { navigate, RouteComponentProps, useParams } from '@reach/router'
import { Text } from '@interco/inter-ui/components/Text'
import { Button } from '@interco/inter-ui/components/Button'

import { Advantages, AdvantagesList, CancelSubscriptionStyles } from './CancelSubscription.styles'
import CancelSubscriptionShimmer from './CancelSubscriptionShimmer'
import { handleTagHeader, handleTagThree, handleTagTwo } from './CancelSubscription.tags'

const CancelSubscription = (_: RouteComponentProps) => {
  const params = useParams<{ subscriptionId: string }>()

  const { t } = useTranslation()
  const { configs } = useConfigs()
  const [cancelation, getCancelation] = useAsync<CancelSubscriptionResponse>('get')

  const handleCancel = async () => {
    handleTagThree({
      plan_name: configs.subscriber.planName,
      plan_value: configs.subscriber.planValue,
      subscriber: `${configs.subscriber.duoGourmetInterCustomer}`,
    })
    if (cancelation.data.refundedFlow)
      navigate(
        `${process.env.REACT_APP_MKTPL_CANCELLATION_URL}/?subscriptionId=${params.subscriptionId}`,
      )
    else {
      try {
        bridgeNative.execute({
          action: 'sendToCancelSubscription',
          metadata: { productId: 'DUO_GOURMET', subscriptionId: params.subscriptionId },
        })
        navigate('/')
      } catch (error) {
        window.console.error(error)
      }
    }
  }

  const handleKeepSaving = async () => {
    handleTagTwo({
      plan_name: configs.subscriber.planName,
      plan_value: configs.subscriber.planValue,
      subscriber: `${configs.subscriber.duoGourmetInterCustomer}`,
    })
    navigate('/')
  }

  const fetchCancellation = React.useCallback(async () => {
    try {
      await getCancelation(`${process.env.REACT_APP_API}/cancelation`)
    } catch (error) {
      window.console.error(error)
    }
  }, [getCancelation])

  React.useEffect(() => {
    fetchCancellation()
  }, [fetchCancellation])

  if (cancelation.isError) {
    return (
      <>
        <AppBar name={t('subscription.cancelation')} />
        <Error />
      </>
    )
  }

  if (cancelation.isPending) {
    return <CancelSubscriptionShimmer />
  }

  if (cancelation.isSuccess) {
    return (
      <>
        <AppBar name={t('subscription.cancelation')} backTag={handleTagHeader} />
        <CancelSubscriptionStyles fullHeight style={{ paddingTop: 60 }}>
          <SpaceBetween direction="column">
            <div>
              <TotalSaved
                value={cancelation.data.totalSaved}
                showHelpIcon={false}
                analyticsTag={() => false}
              />

              <Text className="text" variant="headline-h2" semiBold>
                {t('subscription.cancelationMessage')}
              </Text>

              {cancelation.data?.advantagesList.map((e) => (
                <Advantages key={e.title}>
                  <div>
                    <DynamicIcon name={e.icon} height={24} width={24} color="var(--gray500)" />
                  </div>
                  <AdvantagesList>
                    <Text variant="body-3" bold colorWeight={500} style={{ marginBottom: '8px' }}>
                      {e.title}
                    </Text>
                    <Text variant="body-3">{e.text}</Text>
                  </AdvantagesList>
                </Advantages>
              ))}

              <div className="btn-container">
                <Button variant="secondary" className="button" onClick={handleCancel}>
                  {t('subscription.cancel')}
                </Button>
                <Button variant="primary" className="button" onClick={handleKeepSaving}>
                  {t('subscription.keepSaving')}
                </Button>
              </div>
            </div>
          </SpaceBetween>
        </CancelSubscriptionStyles>
      </>
    )
  }
  return null
}

export default CancelSubscription
