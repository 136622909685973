import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react'

import useAsync, { TAsyncRun, UseAsyncResponse } from 'hooks/UseAsync'
import { ApiResponsePagination } from 'types/http/ApiResponse'
import { HomeResponse, RestaurantResume } from 'types/Restaurant'

export type TRestaurantsContext = {
  restaurants: RestaurantResume[]
  setRestaurants: Dispatch<SetStateAction<RestaurantResume[]>>
  shouldRefetch: boolean
  setShouldRefetch: Dispatch<SetStateAction<boolean>>
  offsetScroll: number
  setOffsetScroll: Dispatch<SetStateAction<number>>
  response: UseAsyncResponse<ApiResponsePagination<HomeResponse>>
  data: ApiResponsePagination<HomeResponse>
  getRestaurants: TAsyncRun<ApiResponsePagination<HomeResponse>>
  isSuccess: boolean
  isError: boolean
  clearContext: () => void
}

export const RestaurantsContext = createContext<TRestaurantsContext>({} as TRestaurantsContext)
export const useRestaurants = () => useContext(RestaurantsContext)

const RestaurantsProvider: React.FC = ({ children }) => {
  const [restaurants, setRestaurants] = useState<RestaurantResume[]>([])
  const [shouldRefetch, setShouldRefetch] = useState(false)
  const [offsetScroll, setOffsetScroll] = useState(0)
  const [response, getRestaurants] = useAsync<ApiResponsePagination<HomeResponse>>('get')

  const { isError, isSuccess, data } = response
  const clearContext = () => {
    setRestaurants([])
    setShouldRefetch(false)
    setOffsetScroll(0)
  }

  const value = useMemo(
    () => ({
      restaurants,
      setRestaurants,
      response,
      data,
      getRestaurants,
      isError,
      isSuccess,
      shouldRefetch,
      setShouldRefetch,
      offsetScroll,
      setOffsetScroll,
      clearContext,
    }),
    [
      restaurants,
      response,
      getRestaurants,
      isError,
      isSuccess,
      data,
      setRestaurants,
      shouldRefetch,
      setShouldRefetch,
      offsetScroll,
      setOffsetScroll,
    ],
  )

  return <RestaurantsContext.Provider value={value}>{children}</RestaurantsContext.Provider>
}

export default RestaurantsProvider
