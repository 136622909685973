import React, { useState } from 'react'

import { bridgeNative } from 'App'
import ListItem from 'components/ListItem'
import Divider from 'components/Divider'
import { SpaceBetween } from 'App.styles'
import AppBar from 'components/AppBar'
import { ESubscriptionStatus, SubscriptionResponse } from 'types/Subscription'
import useAsync from 'hooks/UseAsync'
import Error from 'components/Error'
import { BottomSheet } from 'components/BottomSheet'
import { useConfigs } from 'contexts/ConfigsContext'
import { useTranslation } from 'react-i18next'

import HelpIcon from '@interco/icons/orangeds/XL/help'
import { navigate, Redirect, RouteComponentProps } from '@reach/router'
import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'
import { Alert } from '@interco/inter-ui/components/Alert'

import * as S from './Subscription.styles'
import SubscriptionShimmer from './SubscriptionShimmer'
import ReactivateSubscription from './ReactivateSubscription'
import PendingPayment from './PendingPayment'
import {
  handleTagReactivateBt,
  handleTagClose,
  handleTagBSBt,
} from './ReactivateSubscription/ReactivateSubscription.tags'
import { handleTagHeader, handleTagPayment, handleTagPendingPayment } from './Subscription.tags'
import { handleTagCancelation } from './CancelSubscription/CancelSubscription.tags'

const Subscription = (_: RouteComponentProps) => {
  const { t } = useTranslation()
  const screenName = t('subscription.screenName')

  const [showRenovationBS, setShowRenovationBS] = useState<boolean>(false)
  const [showBonusBS, setShowBonusBS] = useState<boolean>(false)
  const [showReactivateSubBS, setShowReactivateSubBS] = useState<boolean>(false)
  const [showDiscountBS, setShowDiscountBS] = useState<boolean>(false)
  const [showBonus, setShowBonus] = useState<boolean>(false)
  const [textBonus, setTextBonus] = useState<string>('')
  const [subscription, getSubscription] = useAsync<SubscriptionResponse>('get')
  const [retryPaymentResponse, postRetryPaymentResponse] = useAsync('post')
  const [reactivateRequest, putReactivateRequest] = useAsync('put')
  const [reactivateResponsePage, setReactivateResponsePage] = useState(false)
  const [retryPaymentPage, setRetryPaymentPage] = useState(false)

  const { configs } = useConfigs()

  const bsAutoRenew = (
    <div style={{ marginTop: '12px' }}>
      <Text variant="body-3" color="typography">
        {t('subscription.autoRenewalMessage')}
      </Text>
    </div>
  )

  const bsBonus = (
    <div style={{ marginTop: '12px' }}>
      <Text variant="body-3" color="typography">
        {textBonus}
      </Text>
    </div>
  )

  const bsReactivateSub = (
    <div style={{ marginTop: '12px' }}>
      <Text variant="body-3" color="typography">
        {subscription.data?.reactivateSub?.message}
      </Text>
    </div>
  )

  const bsDiscount = (
    <div style={{ marginTop: '12px' }}>
      <Text variant="body-3" color="typography">
        {t('subscription.discountMessage')}
      </Text>
    </div>
  )

  const fetchSubscription = React.useCallback(async () => {
    try {
      await getSubscription(`${process.env.REACT_APP_API}/subscription`)
    } catch (error) {
      window.console.error(error)
    }
  }, [getSubscription])

  const handlePayment = () => {
    bridgeNative
      .execute({
        action: 'sendToPaymentSubscription',
        metadata: { subscriptionId: subscription.data.subscriptionId },
      })
      .catch(window.console.error)

    handleTagPayment()
  }

  const handleReactivation = async () => {
    try {
      await putReactivateRequest(
        `/app-pf/subscription/v1/subscriptions/${subscription.data?.subscriptionId}/reactivate`,
      )
    } catch (error) {
      window.console.error(`error: ${error}`)
    }

    setReactivateResponsePage(true)

    handleTagBSBt()
    setShowReactivateSubBS(false)
  }

  const handlePendingPayment = async () => {
    try {
      await postRetryPaymentResponse(
        `app-pf/foods/subscription/v1/retryPayment/${subscription.data?.recurrenceId}`,
      )
    } catch (error) {
      window.console.error(`error: ${error}`)
    }

    setRetryPaymentPage(true)
  }

  const handleRetryPayment = () => {
    handleTagPendingPayment()
    handlePendingPayment()
  }

  React.useEffect(() => {
    fetchSubscription()
  }, [fetchSubscription])

  React.useEffect(() => {
    const getBonusText = subscription?.data?.banners.find((banner) =>
      banner.description?.includes('bônus'),
    )?.description

    if (getBonusText) {
      setShowBonus(true)
      setTextBonus(getBonusText)
    }
  }, [subscription])

  if (!configs.subscriber.duoGourmetInterCustomer) {
    return <Redirect noThrow to="/" />
  }

  if (reactivateResponsePage && reactivateRequest.isError) {
    return (
      <ReactivateSubscription
        requestResponse={false}
        setReactivateResponsePage={setReactivateResponsePage}
      />
    )
  }

  if (reactivateResponsePage && reactivateRequest.isSuccess) {
    return (
      <ReactivateSubscription
        requestResponse
        setReactivateResponsePage={setReactivateResponsePage}
      />
    )
  }

  if (retryPaymentPage && retryPaymentResponse.isError) {
    return <PendingPayment requestResponse={false} setRetryPaymentPage={setRetryPaymentPage} />
  }

  if (retryPaymentPage && retryPaymentResponse.isSuccess) {
    return <PendingPayment requestResponse setRetryPaymentPage={setRetryPaymentPage} />
  }

  if (subscription.isError) {
    return (
      <>
        <AppBar name={screenName} />
        <Error />
      </>
    )
  }

  if (subscription.isPending) {
    return <SubscriptionShimmer />
  }

  if (subscription.isSuccess) {
    return (
      <>
        <AppBar
          name={screenName}
          goBackNative={subscription.data.subscriptionStatus === ESubscriptionStatus.PENDING}
          backTag={handleTagHeader}
        />
        <S.Wrapper fullHeight style={{ paddingTop: 60 }}>
          <S.SubscriptionInfo>
            <SpaceBetween direction="row">
              <Text variant="caption-1">{t('subscription.planName')}</Text>
              <Text variant="caption-1" colorWeight={500} bold>
                {subscription.data.planName}
              </Text>
            </SpaceBetween>

            <SpaceBetween direction="row">
              <Text variant="caption-1">
                {t('subscription.nextRenewal')}
                <HelpIcon
                  width={16}
                  height={16}
                  color="var(--primary500)"
                  style={{ position: 'absolute', marginLeft: '5px' }}
                  onClick={() => setShowRenovationBS(true)}
                />
              </Text>
              <Text variant="caption-1" colorWeight={500} bold>
                {subscription.data.renewalDate}
              </Text>

              <BottomSheet
                showBottomSheet={showRenovationBS}
                onDismiss={() => setShowRenovationBS(false)}
                headerLabel={t('subscription.autoRenewal')}
                headerBtLabel={t('utils.closeBt')}
                headerBtHandler={() => setShowRenovationBS(false)}
                footerBtLabel={t('utils.understoodBt')}
                footerBtHandler={() => setShowRenovationBS(false)}
                bsContent={bsAutoRenew}
              />
            </SpaceBetween>

            <SpaceBetween direction="row">
              <Text variant="caption-1">{t('subscription.planValue')}</Text>
              <Text variant="caption-1" colorWeight={500} bold>
                {subscription.data.fullSubscriptionValue}
              </Text>
            </SpaceBetween>

            {subscription.data.discountValue && (
              <SpaceBetween direction="row">
                <Text variant="caption-1">
                  {t('subscription.subscriptionDiscount')}
                  <HelpIcon
                    width={16}
                    height={16}
                    color="var(--primary500)"
                    style={{ position: 'absolute', marginLeft: '5px' }}
                    onClick={() => setShowDiscountBS(true)}
                  />
                </Text>
                <Text variant="caption-1" colorWeight={500} bold className="discount">
                  - {subscription.data.discountValue}
                </Text>

                <BottomSheet
                  showBottomSheet={showDiscountBS}
                  onDismiss={() => setShowDiscountBS(false)}
                  headerLabel={t('subscription.subscriptionDiscount')}
                  headerBtLabel={t('utils.closeBt')}
                  headerBtHandler={() => setShowDiscountBS(false)}
                  footerBtLabel={t('utils.understoodBt')}
                  footerBtHandler={() => setShowDiscountBS(false)}
                  bsContent={bsDiscount}
                />
              </SpaceBetween>
            )}

            {showBonus && (
              <SpaceBetween direction="row">
                <Text variant="caption-1">
                  {t('subscription.bonus')}
                  <HelpIcon
                    width={16}
                    height={16}
                    color="var(--primary500)"
                    style={{ position: 'absolute', marginLeft: '5px' }}
                    onClick={() => setShowBonusBS(true)}
                  />
                </Text>
                <Text variant="caption-1" colorWeight={500} bold>
                  {t('subscription.2months')}
                </Text>

                <BottomSheet
                  showBottomSheet={showBonusBS}
                  headerLabel={t('subscription.bonus')}
                  onDismiss={() => setShowBonusBS(false)}
                  headerBtLabel={t('utils.closeBt')}
                  headerBtHandler={() => setShowBonusBS(false)}
                  footerBtLabel={t('utils.understoodBt')}
                  footerBtHandler={() => setShowBonusBS(false)}
                  bsContent={bsBonus}
                />
              </SpaceBetween>
            )}
          </S.SubscriptionInfo>

          <S.AlertsContainer>
            {subscription.data.subscriptionStatus === ESubscriptionStatus.CANCELED && (
              <Alert title={t('subscription.subscriptionCanceled')} type="error">
                {subscription.data.statusMessage}
              </Alert>
            )}

            {subscription.data.reactivateSub && (
              <>
                <Button
                  variant="secondary"
                  style={{ width: '100%' }}
                  onClick={() => {
                    handleTagReactivateBt()
                    setShowReactivateSubBS(true)
                  }}
                >
                  {t('subscription.reactivateSubscription')}
                </Button>

                <BottomSheet
                  showBottomSheet={showReactivateSubBS}
                  onDismiss={() => {
                    setShowReactivateSubBS(false)
                  }}
                  headerBtLabel={t('utils.closeBt')}
                  headerBtHandler={() => {
                    handleTagClose()
                    setShowReactivateSubBS(false)
                  }}
                  footerBtLabel={subscription.data?.reactivateSub?.button}
                  footerBtHandler={handleReactivation}
                  footerBtLoading={reactivateRequest.isPending}
                  headerLabel={subscription.data?.reactivateSub?.title}
                  bsContent={bsReactivateSub}
                />
              </>
            )}

            {subscription.data.subscriptionStatus === ESubscriptionStatus.PENDING && (
              <Alert title={t('subscription.subscriptionPending')} type="warning">
                {subscription.data.statusMessage}
              </Alert>
            )}

            {subscription.data.retryPayment && (
              <>
                <Alert title={t('subscription.paymentPending')} type="warning">
                  {subscription.data.statusMessage}
                </Alert>
                <Button
                  variant="secondary"
                  style={{ width: '100%' }}
                  isLoading={retryPaymentResponse.isPending}
                  onClick={handleRetryPayment}
                >
                  {t('subscription.payPendingSubscription')}
                </Button>
              </>
            )}
          </S.AlertsContainer>

          <Divider
            borderWidth={8}
            style={{ position: 'absolute', left: 0, width: '100%' }}
            color="var(--gray100)"
          />

          <S.LinkMenu>
            <ListItem
              label={t('subscription.payment')}
              arrow
              onClick={handlePayment}
              disabled={
                subscription.data.subscriptionStatus === ESubscriptionStatus.CANCELED ||
                subscription.data.subscriptionStatus === ESubscriptionStatus.PENDING
              }
            />

            <Divider style={{ margin: 0 }} />

            <ListItem
              label={t('subscription.cancelation')}
              arrow
              disabled={
                subscription.data.subscriptionStatus === ESubscriptionStatus.CANCELED ||
                subscription.data.subscriptionStatus === ESubscriptionStatus.PENDING
              }
              onClick={async () => {
                handleTagCancelation({
                  plan_name: configs.subscriber.planName,
                  plan_value: configs.subscriber.planValue,
                })
                navigate(`assinatura-pagamento/cancelamento/${subscription.data.subscriptionId}`)
              }}
            />
          </S.LinkMenu>
        </S.Wrapper>
      </>
    )
  }
  return null
}

export default Subscription
