import { QueryParamRestaurant } from 'contexts/FiltersContext'
import { StateCitiesObject } from 'types/Filters'

interface LocationFilterCommon {
  statesAndCities: StateCitiesObject[]
  actualState: string
}
interface LocationFilterRestaurant {
  type: 'restaurant'
  fetchRestaurants: (newQueryParam?: QueryParamRestaurant) => void
  fetchHomeResponse?: never
  setSearchRestaurantsText: (text: string) => void
  setLoadingShimmerHeader: (loading: boolean) => void
}
interface LocationFilterHome {
  type: 'home'
  fetchRestaurants?: never
  fetchHomeResponse: (city: string) => void
  setSearchRestaurantsText?: never
  setLoadingShimmerHeader?: never
}

export type LocationFilterProps = LocationFilterCommon &
  (LocationFilterRestaurant | LocationFilterHome)

type StateFilterCommon = {
  actualState: string
  tempState: string
  setTempState: (state: string) => void
}

export type StateFilterProps<T> = T extends 'restaurant'
  ? { type: T } & StateFilterCommon & LocationFilterCommon & LocationFilterRestaurant
  : { type: T } & StateFilterCommon & LocationFilterCommon & LocationFilterHome

export enum Country {
  Brazil = 'BR',
  USA = 'US',
}

export interface StateSelectionProps {
  statesAndCities: {
    label: string
    selected: boolean
    value: string
    cities: {
      label: string
      selected: boolean
      value: string
    }[]
  }[]
}

export interface StateCitiesProps {
  label: string
  selected: boolean
  value: string
  cities: {
    label: string
    selected: boolean
    value: string
  }[]
}
