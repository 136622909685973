import React, { useCallback, useEffect, useState } from 'react'

import { AppContainer } from 'App.styles'
import Divider from 'components/Divider'
import ImgSuccess from 'assets/imgs/blackcard/success.svg'
import useAsync from 'hooks/UseAsync'
import { ExperienceUseEvents } from 'types/Experiences'
import AppBar from 'components/AppBar'
import Error from 'components/Error'
import { ECurrentMainTab } from 'enums/EContexts'
import { useTranslation } from 'react-i18next'

import CopyIcon from '@interco/icons/orangeds/XL/copy'
import ScheduleIcon from '@interco/icons/orangeds/XL/agenda'
import LocationIcon from '@interco/icons/orangeds/SM/location'
import UserAccountIcon from '@interco/icons/orangeds/MD/user-account'
import { RouteComponentProps, useNavigate } from '@reach/router'
import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'
import { Alert } from '@interco/inter-ui/components/Alert'
import { interWbNavigate, interWbSession } from '@interco/inter-webview-bridge'

import * as S from './ExperiencesBenefit.styles'
import * as T from './ExperiencesBenefit.tags'

interface ExperiencesBenefitProps {
  id: string
}

const ExperiencesBenefit = ({ location }: RouteComponentProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const screenTitle = t('experiencesBenefit.experiences')

  const { id } = location?.state as ExperiencesBenefitProps
  const [userExperienceEvent, getUserExperienceEvent] = useAsync<ExperienceUseEvents>('get')
  const iconProps = { width: 24, height: 24, color: 'var(--gray500)', className: 'item-icon' }
  const [hasCopyBt, setHasCopyBt] = useState(false)

  useEffect(() => {
    fetchExperience()
  }, [])

  const fetchExperience = useCallback(async () => {
    try {
      const res = await getUserExperienceEvent(
        `${process.env.REACT_APP_API}/useEvents/experiences/${id}`,
      )

      setHasCopyBt(!!res.instructions.partner && !!res.instructions.link)

      T.handleBenefitSuccess({ event: res.experienceTitle, date: res.createdAt })
    } catch (error) {
      window.console.log(error)
    }
  }, [])

  const handleCopyCode = async (discountCode: string) =>
    interWbSession
      .copyToClipboard(t('experiencesBenefit.discountCode'), discountCode)
      .catch(window.console.error)

  if (userExperienceEvent?.isError) {
    return (
      <>
        <AppBar name={screenTitle} showHomeAction />
        <Error />
      </>
    )
  }

  if (userExperienceEvent?.isSuccess) {
    return (
      <>
        <S.Container>
          <Text variant="headline-h3" semiBold>
            {t('experiencesBenefit.experiences')}
          </Text>
          <S.SuccessImg>
            <img src={ImgSuccess} alt="" className="success-img" />
          </S.SuccessImg>
          <Text variant="headline-h2" semiBold>
            {t('experiencesBenefit.availableCode')}
          </Text>
          <Text variant="body-2" className="description-text">
            {t('experiencesBenefit.showCodeAtLocation')}
          </Text>
          <S.BenefitInfo>
            {hasCopyBt ? (
              <S.CopyCode>
                <Text variant="headline-h2" semiBold>
                  {userExperienceEvent.data?.code}
                </Text>
                <CopyIcon
                  height={30}
                  width={30}
                  color="var(--primary500)"
                  onClick={() => handleCopyCode(userExperienceEvent.data?.code)}
                />
              </S.CopyCode>
            ) : (
              <S.BenefitCode>
                <Text variant="headline-h2" semiBold>
                  {userExperienceEvent.data?.code}
                </Text>
              </S.BenefitCode>
            )}
            <Divider />
            <S.ListItem>
              <UserAccountIcon {...iconProps} />
              <Text variant="body-3">{userExperienceEvent.data?.userName}</Text>
            </S.ListItem>
            <S.ListItem>
              <LocationIcon {...iconProps} />
              <Text variant="body-3">{userExperienceEvent.data?.experienceTitle}</Text>
            </S.ListItem>
            <S.ListItem>
              <ScheduleIcon {...iconProps} />
              <Text variant="body-3">{userExperienceEvent.data?.useDate}</Text>
            </S.ListItem>
            <S.AlertStyle>
              <Alert type="grayscale">{t('experiencesBenefit.subcriptionHolderDescription')}</Alert>
            </S.AlertStyle>
          </S.BenefitInfo>
          <S.AlertWrapper>
            <Alert title={userExperienceEvent.data?.instructions.title} type="grayscale">
              <div
                dangerouslySetInnerHTML={{
                  __html: userExperienceEvent.data?.instructions.description,
                }}
              />
            </Alert>
          </S.AlertWrapper>
        </S.Container>
        <AppContainer>
          {hasCopyBt ? (
            <>
              <Button
                style={{ width: '100%' }}
                data-testid="experience-button"
                onClick={async () => {
                  T.handleRateNow()
                  handleCopyCode(userExperienceEvent.data?.code)
                  interWbNavigate.openDeepLink(userExperienceEvent.data?.instructions?.link)
                }}
              >
                {t('experiencesBenefit.copyCodeGoToSite')}
              </Button>
              <Button
                data-testid="experience-button"
                style={{ width: '100%', marginTop: '1rem' }}
                variant="secondary"
                onClick={() => navigate(`/home/${ECurrentMainTab.experiences}`)}
              >
                {t('experiencesBenefit.seeExperiences')}
              </Button>
            </>
          ) : (
            <Button
              data-testid="experience-button"
              style={{ width: '100%', marginTop: '1rem' }}
              onClick={() => navigate(`/home/${ECurrentMainTab.experiences}`)}
            >
              {t('experiencesBenefit.seeExperiences')}
            </Button>
          )}
        </AppContainer>
      </>
    )
  }
  return null
}

export default ExperiencesBenefit
