import { useEffect, useState } from 'react'

import { BottomSheet } from 'components/BottomSheet'
import { QueryParamRestaurant, useFilters } from 'contexts/FiltersContext'
import { useBSState } from 'contexts/FiltersBottomsheetContext'
import { useConfigs } from 'contexts/ConfigsContext'
import { useTranslation } from 'react-i18next'

import { LocationFilterProps, StateCitiesProps } from './LocationFilter.types'
import { handleTagChangeState } from './LocationFilterTags'
import StateFilter from './StateFilter'
import { LocationBsContent } from './LocationBsContent'

export function LocationFilter({
  type,
  statesAndCities,
  fetchRestaurants,
  fetchHomeResponse,
  setSearchRestaurantsText,
  setLoadingShimmerHeader,
  actualState,
}: LocationFilterProps) {
  const { queryParam, setQueryParam } = useFilters()
  const { bsStateParam, setBsStateParam } = useBSState()
  const { configs } = useConfigs()
  const { t } = useTranslation()

  const [tempState, setTempState] = useState(queryParam.state)
  const [selectedState, setSelectedState] = useState<StateCitiesProps>({
    label: '',
    selected: false,
    value: '',
    cities: [],
  })

  const getSelectedState = (newState?: string) => {
    let stateObject = statesAndCities.filter((state) => state.value === newState)
    if (!stateObject.length) {
      stateObject = statesAndCities.filter((state) => state.selected)
    }
    if (stateObject[0].cities.length === 1 && !stateObject[0].cities[0].selected) {
      stateObject[0].cities[0].selected = true
      onFilter(stateObject[0].cities[0].value)
    }
    setSelectedState(stateObject[0])
  }

  const onFilter = async (newCity?: string) => {
    setBsStateParam({
      ...bsStateParam,
      showBottomSheetLocation: false,
      showBottomSheetState: false,
    })
    setSearchRestaurantsText?.('')
    setLoadingShimmerHeader?.(true)
    if (type === 'restaurant') {
      fetchRestaurants?.({
        ...queryParam,
        name: '',
        city: newCity ?? selectedState.cities?.filter((d) => d.selected)[0]?.value,
        cookings: [],
        districts: [],
        days: [],
        mealTimes: [],
        orderMethod: [],
        price: [],
        favorites: false,
        newRestaurant: false,
      })
    } else {
      fetchHomeResponse?.(newCity ?? selectedState.cities?.filter((d) => d.selected)[0]?.value)
    }
  }

  const onDismiss = () => {
    getSelectedState(actualState)
    setTempState(actualState)
    setQueryParam({ ...queryParam, state: actualState })
    setBsStateParam({
      ...bsStateParam,
      showBottomSheetLocation: false,
      showBottomSheetState: false,
    })
  }

  const handleChangeState = () => {
    handleTagChangeState({
      plan_name: configs.subscriber.planName,
      plan_value: configs.subscriber.planValue,
      subscriber: `${configs.subscriber.duoGourmetInterCustomer}`,
    }).catch(window.console.error)
    setBsStateParam({ ...bsStateParam, showBottomSheetLocation: false, showBottomSheetState: true })
  }

  useEffect(() => {
    getSelectedState(tempState)
  }, [tempState])

  return (
    <>
      <BottomSheet
        showBottomSheet={bsStateParam?.showBottomSheetLocation}
        onDismiss={onDismiss}
        headerLabel={selectedState?.label}
        headerBtLabel={t('filters.changeState')}
        headerBtHandler={handleChangeState}
        bsContent={
          <LocationBsContent
            selectedState={selectedState}
            setSelectedState={setSelectedState}
            fetchRestaurants={fetchRestaurants!}
            onFilter={onFilter}
            type={type}
          />
        }
      />
      {type === 'home' ? (
        <StateFilter
          type={type}
          fetchHomeResponse={fetchHomeResponse as (city: string) => void}
          statesAndCities={statesAndCities}
          actualState={actualState}
          tempState={tempState ?? ''}
          setTempState={setTempState}
        />
      ) : (
        <StateFilter
          type={type}
          fetchRestaurants={fetchRestaurants as (newQueryParam?: QueryParamRestaurant) => void}
          statesAndCities={statesAndCities}
          actualState={actualState}
          tempState={tempState ?? ''}
          setTempState={setTempState}
          setSearchRestaurantsText={setSearchRestaurantsText as (text: string) => void}
          setLoadingShimmerHeader={setLoadingShimmerHeader as (loading: boolean) => void}
        />
      )}
    </>
  )
}

export default LocationFilter
