import React, { useEffect, useState } from 'react'

import { BottomSheet } from 'components/BottomSheet'
import { useFilters } from 'contexts/FiltersContext'
import { useBSState } from 'contexts/FiltersBottomsheetContext'
import { useConfigs } from 'contexts/ConfigsContext'
import { useDebouncedCallback } from 'use-debounce'
import { useTranslation } from 'react-i18next'

import { Text } from '@interco/inter-ui/components/Text'
import { Input } from '@interco/inter-ui/components/Input'
import SearchIcon from '@interco/icons/orangeds/XL/search'
import ChevronRightIcon from '@interco/icons/orangeds/XL/chevron-right'

import {
  StateSelectionProps,
  StateCitiesProps,
  StateFilterProps,
  Country,
} from './LocationFilter.types'
import { BsBody, Container, FilterItem, SearchInput, StateItem } from './LocationFilter.styles'
import { handleTagState, handleTagSearch, handleTagCountry } from './LocationFilterTags'

export function StateFilter<T>({
  type,
  statesAndCities,
  fetchRestaurants,
  fetchHomeResponse,
  setSearchRestaurantsText,
  setLoadingShimmerHeader,
  actualState,
  tempState,
  setTempState,
}: StateFilterProps<T>) {
  const { queryParam, setQueryParam } = useFilters()
  const { bsStateParam, setBsStateParam } = useBSState()
  const { configs } = useConfigs()
  const { t } = useTranslation()

  const iconSize = { width: 20, height: 20 }

  const [searchText, setSearchText] = useState('')
  const [selectedCountry, setSelectedCountry] = useState<Country>(Country.Brazil)
  const [states, setStates] = useState<StateSelectionProps>({
    statesAndCities: JSON.parse(JSON.stringify(statesAndCities)),
  })
  const [selectedState, setSelectedState] = useState<StateCitiesProps>({
    label: '',
    selected: false,
    value: '',
    cities: [],
  })

  const getSelectedState = (newState?: string) => {
    let stateObject = statesAndCities.filter((state) => state.value === newState)
    if (stateObject?.length === 0) {
      stateObject = statesAndCities.filter((state) => state.selected)
    }
    if (stateObject[0].cities.length === 1 && !stateObject[0].cities[0].selected) {
      stateObject[0].cities[0].selected = true
      onFilter(stateObject[0].cities[0].value)
    }
    setSelectedState(stateObject[0])
  }

  useEffect(() => {
    getSelectedState(tempState)
  }, [tempState])

  const handleChange = (state: string) => {
    handleTagState({
      content_state: state,
      plan_name: configs.subscriber.planName,
      plan_value: configs.subscriber.planValue,
      subscriber: `${configs.subscriber.duoGourmetInterCustomer}`,
    }).catch(window.console.error)
    setTempState(state)
    setQueryParam({ ...queryParam, state })
    setBsStateParam({ ...bsStateParam, showBottomSheetState: false, showBottomSheetLocation: true })
  }

  const onFilter = async (newCity?: string) => {
    if (type === 'restaurant') {
      fetchRestaurants?.({
        ...queryParam,
        name: '',
        city: newCity ?? selectedState.cities?.filter((d) => d.selected)[0]?.value,
        cookings: [],
        districts: [],
        days: [],
        mealTimes: [],
        orderMethod: [],
        favorites: false,
        newRestaurant: false,
      })
    } else {
      fetchHomeResponse?.(newCity ?? selectedState.cities?.filter((d) => d.selected)[0]?.value)
    }
    onClose()
    setSearchRestaurantsText?.('')
    setLoadingShimmerHeader?.(true)
  }

  const onClose = () => {
    setBsStateParam({
      ...bsStateParam,
      showBottomSheetLocation: false,
      showBottomSheetState: false,
    })
  }

  const onDismiss = () => {
    getSelectedState(actualState)
    onClose()
    setTempState(actualState)
    setQueryParam({ ...queryParam, state: actualState })
    setSelectedCountry(Country.Brazil)
    handleSearch('')
    setSearchText('')
  }

  const handleSearch = useDebouncedCallback(async (value: string) => {
    if (value.length === 0) {
      setStates({ statesAndCities })
    } else {
      const filteredStates = statesAndCities.filter((state) =>
        state?.label?.toLowerCase().includes(value?.toLowerCase()),
      )

      handleTagSearch({ search_term: value })
      setStates({ statesAndCities: filteredStates })
    }
  }, 500)

  const bsContent = (
    <Container>
      <SearchInput>
        <Input
          iconLeft={<SearchIcon {...iconSize} color="var(--primary500)" className="iconStyle" />}
          value={searchText}
          type="search"
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
              e.currentTarget.blur()
            }
          }}
          placeholder={t('utils.search')}
          className="inputStyle"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleSearch(e.target.value ?? '')
            setSearchText(e.target.value)
          }}
        />
      </SearchInput>
      <FilterItem>
        <input
          className="input"
          type="checkbox"
          id={Country.Brazil}
          name={Country.Brazil}
          onChange={(e) => {
            setSelectedCountry(e.target.name as Country)
            handleTagCountry({ content_country: 'Brasil' })
          }}
          checked={selectedCountry === Country.Brazil}
        />
        <label className="label" htmlFor={Country.Brazil}>
          {t('countries.brazil')}
        </label>
      </FilterItem>
      <FilterItem>
        <input
          className="input"
          type="checkbox"
          id={Country.USA}
          name={Country.USA}
          onChange={(e) => {
            setSelectedCountry(e.target.name as Country)
            handleTagCountry({ content_country: 'Estados Unidos' })
          }}
          checked={selectedCountry === Country.USA}
        />
        <label className="label" htmlFor={Country.USA}>
          {t('countries.usa')}
        </label>
      </FilterItem>

      {selectedCountry === Country.Brazil &&
        states.statesAndCities.map((state) => (
          <StateItem key={state.label} onClick={() => handleChange(state.value)}>
            <Text variant="caption-1" color="typography" colorWeight={500} bold>
              {state.label}
            </Text>
            <ChevronRightIcon
              width={18}
              height={18}
              color="var(--primary500)"
              className="icon-style"
            />
          </StateItem>
        ))}

      {selectedCountry === Country.USA && (
        <BsBody>
          <Text variant="headline-h1" color="typography" semiBold colorWeight={500}>
            {t('filters.comingSoon')}
          </Text>
          <Text
            variant="body-3"
            color="typography"
            style={{ textAlign: 'center', marginBottom: 60 }}
            colorWeight={500}
          >
            {t('filters.comingSoonMessage')}
          </Text>
        </BsBody>
      )}
    </Container>
  )

  return (
    <BottomSheet
      showBottomSheet={bsStateParam?.showBottomSheetState}
      onDismiss={onDismiss}
      headerLabel={t('filters.states')}
      headerBtLabel={t('utils.closeBt')}
      headerBtHandler={onDismiss}
      bsContent={bsContent}
    />
  )
}

export default StateFilter
