import React from 'react'

import { BottomSheet } from 'components/BottomSheet'
import { useBSState } from 'contexts/FiltersBottomsheetContext'
import { IBanner } from 'types/Campaign'
import PromotionalCarousel from 'components/PromotionalCarousel/PromotionalCarousel'
import { useTranslation } from 'react-i18next'

import { Text } from '@interco/inter-ui/components/Text'

interface IInvalidAssinatureBS {
  text: string
  button: string
  isDuoGourmetLegacyUser?: boolean
  blackCardBanners?: IBanner[]
  pessoaFisica?: boolean
  canSubscribe?: {
    canSubscribe: boolean
    text: string
    button: string
  }
}

const InvalidAssignatureBs = ({
  text,
  button,
  isDuoGourmetLegacyUser,
  blackCardBanners,
  pessoaFisica,
  canSubscribe,
}: IInvalidAssinatureBS) => {
  const { bsStateParam, setBsStateParam } = useBSState()
  const { t } = useTranslation()

  const bsContent = (
    <>
      <div style={{ marginTop: '12px' }}>
        <Text variant="body-3">{text}</Text>
      </div>

      {isDuoGourmetLegacyUser && blackCardBanners && blackCardBanners.length > 0 && canSubscribe && (
        <div style={{ marginTop: '24px' }}>
          <PromotionalCarousel
            banners={blackCardBanners}
            pessoaFisica={pessoaFisica ?? false}
            canSubscribe={canSubscribe}
            bannerTag={{ ref_figma: '', screen: '', flow: '', subscriber: '' }}
          />
        </div>
      )}
    </>
  )

  const handleBSClose = () =>
    setBsStateParam({ ...bsStateParam, showBottomSheetInvalidAssignature: false })

  return (
    <BottomSheet
      data-testid="invalid-assignature-bs"
      showBottomSheet={bsStateParam?.showBottomSheetInvalidAssignature}
      onDismiss={handleBSClose}
      headerLabel={t('subscription.subscriptionUnavailable')}
      headerBtHandler={handleBSClose}
      footerBtLabel={button}
      footerBtHandler={handleBSClose}
      bsContent={bsContent}
    />
  )
}

export default InvalidAssignatureBs
