import React, { useCallback } from 'react'

import { handleTagRules } from 'pages/RestaurantsDetails/RestaurantsDetails.tags'
import { useConfigs } from 'contexts/ConfigsContext'
import { useTranslation } from 'react-i18next'

import ChevronUpIcon from '@interco/icons/orangeds/XL/chevron-up'
import ChevronDownIcon from '@interco/icons/orangeds/XL/chevron-down'
import { Text } from '@interco/inter-ui/components/Text'
import { Accordion } from '@interco/inter-ui/components/Accordion'

import { CustomLabelContainer, ToggleIcon } from './RulesList.styles'

export interface RulesListProps {
  texts: string[]
  restaurantName: string
}

const iconProps = { height: 20, width: 20, color: 'var(--primary500)' }

export const RulesList = ({ texts = [], restaurantName }: RulesListProps) => {
  const [expanded, setExpanded] = React.useState(true)
  const { configs } = useConfigs()
  const { t } = useTranslation()

  const handleClick = () => {
    handleTagRules({
      restaurant: restaurantName,
      plan_name: configs.subscriber.planName,
      plan_value: configs.subscriber.planValue,
    })
    setExpanded(!expanded)
  }

  const CustomLabel = useCallback(
    () => (
      <CustomLabelContainer>
        <Text variant="body-3" bold colorWeight={500}>
          {t('restaurantsDetails.usageRules')}
        </Text>
        <ToggleIcon>
          {expanded ? <ChevronUpIcon {...iconProps} /> : <ChevronDownIcon {...iconProps} />}
        </ToggleIcon>
      </CustomLabelContainer>
    ),
    [expanded],
  )

  return (
    <Accordion
      labelComponent={<CustomLabel />}
      expanded={expanded}
      onClick={handleClick}
      data-testid="accordion-click"
    >
      <ul style={{ listStyle: 'none', color: 'var(--gray400)' }}>
        {texts.map((e, index) => (
          <li key={e} style={{ margin: '0.5rem 0' }}>
            <Text variant="caption-1" data-testid={e}>
              {`${index + 1}) ${e}`}
            </Text>
          </li>
        ))}
      </ul>
    </Accordion>
  )
}

export default RulesList
