import React, { useEffect, useState } from 'react'

import OnboardingFirst from 'assets/imgs/onboarding_01.png'
import OnboardingSecond from 'assets/imgs/onboarding_02.png'
import OnboardingThird from 'assets/imgs/onboarding_03.png'
import OnboardingFourth from 'assets/imgs/onboarding_04.png'
import OnboardingFifth from 'assets/imgs/onboarding_05.png'
import OnboardingSixth from 'assets/imgs/onboarding_06.png'
import OnboardingSeventh from 'assets/imgs/onboarding_07.png'
import { useConfigs } from 'contexts/ConfigsContext'

import { navigate, RouteComponentProps } from '@reach/router'
import { Button } from '@interco/inter-ui/components/Button'
import ChevronRightIcon from '@interco/icons/orangeds/XL/chevron-right'
import ArrowLeftIcon from '@interco/icons/orangeds/XL/arrow-left'
import { Text } from '@interco/inter-ui/components/Text'

import {
  AppBarContainer,
  ButtonContainer,
  Container,
  LeftButton,
  Step,
  StepsContainer,
} from './Onboarding.styles'
import { handleTagLoad, handleTagNext, handleTagSkip } from './Onboarding.tags'

const Onboarding = (_: RouteComponentProps) => {
  const [stepIndex, setStepIndex] = useState(0)
  const { configs } = useConfigs()
  const screenName = 'Onboarding'

  const stepsContent = [
    {
      title: 'Bem-vindo ao Duo Gourmet',
      image: OnboardingFirst,
      description:
        'Economize nos melhores restaurantes do Brasil. São diversas opções para aproveitar o benefício de <b>pedir um prato e ganhar outro</b>.',
      last: false,
    },
    {
      title: 'Conheça o menu',
      image: OnboardingSecond,
      description:
        'Navegue pelo menu pra descobrir os serviços disponíveis. Explore a guia de início e veja dicas de restaurantes e novidades.',
    },
    {
      title: 'Escolha a cidade',
      image: OnboardingThird,
      description:
        'Por onde você for o Duo Gourmet está presente em todo o Brasil, e em breve nos Estados Unidos. Utilize sua localização ou explore a lista de cidades.',
    },
    {
      title: 'Selecione um restaurante',
      image: OnboardingFourth,
      description:
        'Utilize os filtros de busca na guia Restaurantes pra ajudar na sua escolha, você pode usar o Duo Gourmet diversas vezes no mesmo local.',
    },
    {
      title: 'Verifique as regras de uso',
      image: OnboardingFifth,
      description:
        'Consulte os dias e horários disponíveis na página do restaurante para planejar o seu almoço ou jantar.',
    },
    {
      title: 'Use seu benefício e economize',
      image: OnboardingSixth,
      description:
        'Ao fechar a conta, apresente o código gerado ao garçom e pronto! Use seu benefício quantas vezes quiser.',
    },
    {
      title: 'Viva experiências',
      image: OnboardingSeventh,
      description:
        'Além de restaurantes, aproveite o Duo Gourmet em outros estabelecimentos, como hotéis, passeios e atrações turísticas, além de descontos em empresas parceiras.',
    },
  ]

  const lastStepIndex = stepsContent.length - 1

  useEffect(() => {
    handleTagLoad({
      plan_name: configs.subscriber.planName,
      plan_value: configs.subscriber.planValue,
      subscriber: `${configs.subscriber.duoGourmetInterCustomer}`,
      action_id: stepsContent[stepIndex]?.title,
      step: `${stepIndex + 1}`,
    }).catch(window.console.error)
  }, [stepIndex])

  const gotoNext = () => {
    handleTagNext({
      plan_name: configs.subscriber.planName,
      plan_value: configs.subscriber.planValue,
      subscriber: `${configs.subscriber.duoGourmetInterCustomer}`,
    })
    if (stepIndex === lastStepIndex) {
      navigate('/')
    }

    setStepIndex(stepIndex + 1)
  }

  const goFromStep = (index: number) => {
    handleTagNext({
      plan_name: configs.subscriber.planName,
      plan_value: configs.subscriber.planValue,
      subscriber: `${configs.subscriber.duoGourmetInterCustomer}`,
    })
    setStepIndex(index)
  }

  const decreaseStepIndex = () => {
    setStepIndex(stepIndex - 1)
  }

  const handleSkip = async () => {
    handleTagSkip({
      screenStep: `${stepIndex + 1}`,
      plan_name: configs.subscriber.planName,
      plan_value: configs.subscriber.planValue,
      subscriber: `${configs.subscriber.duoGourmetInterCustomer}`,
    }).catch(window.console.error)
    navigate('/')
  }

  const iconSize = { width: 24, height: 24 }

  return (
    <>
      <AppBarContainer>
        {stepIndex !== 0 ? (
          <LeftButton
            data-testid="left-button"
            isShowing
            onClick={decreaseStepIndex}
            style={{ backgroundColor: 'var(--neutral-theme)' }}
          >
            <ArrowLeftIcon {...iconSize} color="var(--primary500)" />
          </LeftButton>
        ) : (
          <LeftButton
            isShowing={false}
            onClick={decreaseStepIndex}
            style={{ backgroundColor: 'var(--neutral-theme)' }}
          >
            <ArrowLeftIcon {...iconSize} color="var(--primary500)" />
          </LeftButton>
        )}

        <Text variant="headline-h3" style={{ fontWeight: 700 }}>
          {screenName}
        </Text>

        <Button
          variant="link"
          type="button"
          data-testid="handle-skip"
          onClick={handleSkip}
          style={{ paddingRight: 24, paddingLeft: 0 }}
        >
          Pular
        </Button>
      </AppBarContainer>

      <Container>
        <img src={stepsContent[stepIndex]?.image} alt="" />
        <div>
          <Text
            variant="headline-big"
            style={{ fontWeight: 700, marginTop: 22, display: 'block', paddingRight: 30 }}
          >
            {stepsContent[stepIndex]?.title}
          </Text>
          <Text variant="body-3" style={{ marginTop: 8, display: 'block' }}>
            <div dangerouslySetInnerHTML={{ __html: stepsContent[stepIndex]?.description }} />
          </Text>
        </div>

        <div className="bottom-content">
          <StepsContainer>
            {stepsContent.map((step, index) => (
              <Step
                data-testid={`step-${index}`}
                key={step?.title}
                isActive={index === stepIndex}
                onClick={() => goFromStep(index)}
              />
            ))}
          </StepsContainer>

          <ButtonContainer>
            <Button
              icon={
                stepIndex !== lastStepIndex ? (
                  <ChevronRightIcon {...iconSize} color="var(--neutral-theme)" />
                ) : null
              }
              onClick={gotoNext}
            >
              {stepIndex === lastStepIndex ? 'Começar a usar' : 'Próximo'}
            </Button>
          </ButtonContainer>
        </div>
      </Container>
    </>
  )
}

export default Onboarding
