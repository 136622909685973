import React, { useState, useEffect, useRef } from 'react'

import Divider from 'components/Divider'
import AppBar from 'components/AppBar'
import NotFound from 'components/NotFound'
import useAsync from 'hooks/UseAsync'
import { ApiResponsePagination, PageResponse } from 'types/http/ApiResponse'
import Utilization, { UseEvents, EUtilization } from 'types/Utilization'
import { SpaceBetween } from 'App.styles'
import queryString from 'query-string'
import InfiniteScroll from 'react-infinite-scroll-component'
import { TotalSaved } from 'components/TotalSaved'
import { Footer } from 'components/FixButton/FixButton.styles'
import { useConfigs } from 'contexts/ConfigsContext'
import { useTranslation } from 'react-i18next'

import ArrowUpIcon from '@interco/icons/orangeds/XL/arrow-up'
import { RouteComponentProps, useNavigate } from '@reach/router'
import { Button } from '@interco/inter-ui/components/Button'
import { Skeleton } from '@interco/inter-ui/components/Skeleton'
import { Text } from '@interco/inter-ui/components/Text'

import { UtilizationStyles } from './RestaurantsRecords.styles'
import * as T from './RestaurantsRecords.tags'

const RestaurantsRecords = (_: RouteComponentProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [utilization, getUtilization] = useAsync<ApiResponsePagination<Utilization>>('get')
  const [use, setUse] = useState<UseEvents[]>([])
  const [scrollId, setScrollId] = useState('')
  const { configs } = useConfigs()
  const [showBackToTop, setShowBackToTop] = useState(false)
  const [loadingShimmer, setLoadingShimmer] = useState(false)
  const [queryParam] = useState<PageResponse>({
    offset: 0,
    size: 10,
  } as PageResponse)

  const useTotal = useRef(0)

  const fetchUtilization = async () => {
    useTotal.current = 0
    setLoadingShimmer(true)
    try {
      const res = await getUtilization(
        `${process.env.REACT_APP_API}/useEvents?${queryString.stringify({
          ...queryParam,
        })}`,
      )
      setUse(res.useEvents)
      setLoadingShimmer(false)
      setScrollId(res.page.scrollId)
      useTotal.current += use.length
    } catch (error) {
      window.console.log(error)
    }
  }

  const ref = useRef(0)

  const next = React.useCallback(async () => {
    try {
      ref.current += queryParam.size
      const res = await getUtilization(
        `${process.env.REACT_APP_API}/useEvents?${queryString.stringify({
          ...queryParam,
          offset: ref.current,
          scrollId,
        })}`,
      )
      setUse((d) => [...d, ...res.useEvents])
      setScrollId(res.page.scrollId)
      useTotal.current += use.length

      res.useEvents.length === 0
        ? T.handleTagLoad({
            screen: 'sem utilizações',
            action_id: 'você ainda não tem utilizações',
          })
        : T.handleTagLoad({
            screen: 'histórico duo gourmet',
            action_id: 'acesso utilizações histórico',
          })
    } catch (error) {
      window.console.log(error)
    }
  }, [getUtilization, queryParam, scrollId])

  const BackTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    window.onscroll = () => {
      setShowBackToTop(window.scrollY > 100)
    }
    fetchUtilization()
  }, [])

  if (utilization.isError) {
    return (
      <>
        <AppBar name={t('restaurantsRecords.useEventsAndHistory')} />
        <UtilizationStyles fullHeight style={{ paddingTop: 100 }}>
          <SpaceBetween direction="column">
            <NotFound
              title={t('restaurantsRecords.noUseEvents')}
              description={t('restaurantsRecords.noUseEventsMessage')}
            />
            <Button
              className="button mt-24"
              variant="primary"
              style={{ marginBottom: 24 }}
              onClick={() => navigate('/')}
            >
              {t('restaurantsRecords.seeRestaurants')}
            </Button>
          </SpaceBetween>
        </UtilizationStyles>
      </>
    )
  }

  return (
    <>
      <AppBar name={t('restaurantsRecords.useEventsAndHistory')} backTag={T.handleTagHeader} />
      <UtilizationStyles fullHeight style={{ paddingTop: 60 }}>
        {loadingShimmer && (
          <div>
            <div className="container-price">
              <Skeleton width="40%" height="14px" />
              <Skeleton width="30%" height="30px" style={{ marginTop: 8 }} />
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Skeleton width="40%" height="14px" style={{ marginTop: 8 }} />
                <Skeleton width="12px" height="12px" style={{ marginTop: 8, marginLeft: 2 }} />
              </div>
            </div>

            {new Array(4).fill('').map((item, i) => (
              <div
                key={`id_${i.toString()}`}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <Skeleton width="60%" height="15px" style={{ marginTop: 10 }} />

                <Skeleton width="40%" height="15px" style={{ marginTop: 10 }} />
                <Skeleton width="30%" height="15px" style={{ marginTop: 10 }} />
                <Skeleton width="100%" height="30px" style={{ marginTop: 10 }} />
                <Divider type="dashed" />
              </div>
            ))}
          </div>
        )}

        {utilization.isSuccess && (
          <TotalSaved
            value={utilization.data.totalSaved}
            analyticsTag={() =>
              T.handleTagSeven({
                plan_name: configs.subscriber.planName,
                plan_value: configs.subscriber.planValue,
                subscriber: `${configs.subscriber.duoGourmetInterCustomer}`,
              })
            }
          />
        )}

        {use.length > 0 && (
          <InfiniteScroll
            dataLength={use?.length}
            next={next}
            hasMore={!!utilization?.data?.page?.scrollId}
            loader={<h4 style={{ marginTop: 10 }}>{t('utils.loading')}</h4>}
          >
            {use?.map((d, idx) => (
              <div key={`${d.sequential}-${d.restaurantName}-${idx + 0}`}>
                <Text
                  variant="body-3"
                  colorWeight={500}
                  className="text bold"
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  {d.restaurantName}
                  {d.userRating?.rating && !d.canRate && (
                    <Button
                      variant="link"
                      size="small"
                      onClick={async () => {
                        T.handleTagEight({
                          restaurant: d.restaurantName,
                          date: d.date,
                          action_id: 'ver avaliação',
                          plan_name: configs.subscriber.planName,
                          plan_value: configs.subscriber.planValue,
                          subscriber: `${configs.subscriber.duoGourmetInterCustomer}`,
                        })
                        navigate('/restaurants-rating', { state: d })
                      }}
                      style={{ fontSize: '12px', whiteSpace: 'nowrap' }}
                    >
                      {t('restaurantsRecords.seeReview')}
                    </Button>
                  )}
                </Text>
                <Text variant="caption-1" className="text">
                  {t('restaurantsRecords.code')}: {d.sequential}
                </Text>
                <Text variant="caption-1" className="text">
                  {d.date.split(' ')[0]}
                </Text>
                {d.canRate && d.modality === EUtilization.PRESENTIAL && (
                  <div style={{ marginTop: 8 }}>
                    <Button
                      variant="primary"
                      size="small"
                      className="button"
                      onClick={async () => {
                        T.handleTagEight({
                          restaurant: d.restaurantName,
                          date: d.date,
                          action_id: 'avaliar experiência',
                          plan_name: configs.subscriber.planName,
                          plan_value: configs.subscriber.planValue,
                          subscriber: `${configs.subscriber.duoGourmetInterCustomer}`,
                        })
                        navigate('/restaurants-rating', { state: d })
                      }}
                    >
                      {t('restaurantsRecords.doReview')}
                    </Button>
                  </div>
                )}
                {d.active && d.modality === EUtilization.PRESENTIAL && (
                  <Button
                    className="button"
                    variant="secondary"
                    size="small"
                    onClick={async () => {
                      navigate('/restaurants-benefit', {
                        state: {
                          ...d,
                          userName: utilization?.data?.userName,
                        },
                      })
                    }}
                  >
                    {t('restaurantsRecords.seeReview')}Ver cupom de benefício
                  </Button>
                )}
                <Divider type="dashed" />
              </div>
            ))}
          </InfiniteScroll>
        )}
        {utilization.isSuccess && utilization.data?.useEvents?.length === 0 && (
          <SpaceBetween direction="column">
            <NotFound
              title={t('restaurantsRecords.noUseEvents')}
              description={t('restaurantsRecords.noUseEventsMessage')}
            />
            <Button
              className="button mt-24"
              variant="primary"
              style={{ marginBottom: 24 }}
              onClick={() => navigate('/')}
            >
              {t('restaurantsRecords.seeRestaurants')}
            </Button>
          </SpaceBetween>
        )}
        <Divider color="var(--gray100)" style={{ margin: '1.5rem 0' }} />
        <Footer style={{ background: 'transparent', border: 'none', paddingRight: '4.5rem' }}>
          <Button
            icon={<ArrowUpIcon width={14} height={14} color="var(--neutral-theme)" />}
            onClick={BackTop}
            size="small"
            style={{ margin: '1rem 0' }}
            className={
              showBackToTop
                ? 'animate__animated animate__bounceInUp'
                : 'animate__animated animate__backOutDown'
            }
          >
            {t('restaurantsRecords.backToTop')}
          </Button>
        </Footer>
      </UtilizationStyles>
    </>
  )
}

export default RestaurantsRecords
