import React, { useEffect } from 'react'

import ImgSuccess from 'assets/imgs/blackcard/success.svg'
import ImgError from 'assets/imgs/blackcard/error.svg'
import { ECurrentMainTab } from 'enums/EContexts'
import { useRestaurants } from 'contexts/RestaurantsContext'
import { useTranslation } from 'react-i18next'

import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'
import { useNavigate } from '@reach/router'

import * as S from './ExperiencesRating.styles'

interface RatingProps {
  success: boolean
}

const RatingResponse = ({ success }: RatingProps) => {
  const navigate = useNavigate()
  const { clearContext } = useRestaurants()
  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' })
  }, [])

  return (
    <S.ResponseWrapper>
      <S.ResponseHeader>
        <Text variant="headline-h3" semiBold>
          {success ? t('experiencesRating.reviewDone') : t('experiencesRating.reviewFailed')}
        </Text>
      </S.ResponseHeader>
      <S.ResponseContainer>
        <S.ResponseImg>
          <img src={success ? ImgSuccess : ImgError} alt="" />
        </S.ResponseImg>
        <S.ResponseText>
          <Text variant="headline-h1" semiBold>
            {success ? t('experiencesRating.thanksForReview') : t('experiencesRating.notRated')}
          </Text>
          <Text variant="body-3">
            {success
              ? t('experiencesRating.reviewMessage')
              : t('experiencesRating.troubleSendingReview')}
          </Text>
        </S.ResponseText>
      </S.ResponseContainer>
      <S.ResponseButtons>
        {success ? (
          <Button
            onClick={() => {
              clearContext()
              navigate(`/home/${ECurrentMainTab.experiences}`)
            }}
          >
            {t('experiencesRating.backToDuoGourmet')}
          </Button>
        ) : (
          <>
            <Button onClick={() => navigate(-1)}>{t('experiencesRating.tryAgain')}</Button>
            <Button
              variant="secondary"
              onClick={() => {
                clearContext()
                navigate(`/home/${ECurrentMainTab.experiences}`)
              }}
            >
              {t('experiencesRating.backToDuoGourmet')}
            </Button>
          </>
        )}
      </S.ResponseButtons>
    </S.ResponseWrapper>
  )
}

export default RatingResponse
