import React, { useEffect, useState } from 'react'

import queryString from 'query-string'
import Error from 'components/Error'
import useAsync from 'hooks/UseAsync'
import LocationBt from 'components/LocationBt/LocationBt'
import Divider from 'components/Divider'
import HomeAnnouncements from 'features/Home/HomeAnnouncements/HomeAnnouncements'
import RecommendationCarousel from 'features/Recommendations/RecommendationCarousel/RecommendationCarousel'
import RecommendationList from 'features/Recommendations/RecommendationList'
import { HomeRecommendationsResponse } from 'types/Home'
import { useFilters } from 'contexts/FiltersContext'
import { useBSState } from 'contexts/FiltersBottomsheetContext'
import { ETrackEvent } from 'enums/ETrackEvent'
import { useConfigs } from 'contexts/ConfigsContext'
import SpecialSelections from 'features/Recommendations/SpecialSelections/SpecialSelections'
import { ECurrentMainTab } from 'enums/EContexts'
import SubscribeBt from 'features/Home/HomeButtons/SubscribeBt'
import LocationFilter from 'components/LocationFilter/LocationFilter'
import { useTranslation } from 'react-i18next'

import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'

import RecommendationsTabShimmer from './RecommendationsTabShimmer'
import { TabsProps } from '../Home.types'
import * as S from './RecommendationsTab.styles'
import * as T from '../Home.tags'

const RecommendationsTab = ({ userLocation, handleTabChange }: TabsProps) => {
  const { t } = useTranslation()
  const { configs } = useConfigs()
  const { queryParam, setQueryParam } = useFilters()
  const { bsStateParam, setBsStateParam } = useBSState()
  const [homeResponse, getHomeResponse] = useAsync<HomeRecommendationsResponse>('get')
  const [citySelection, setCitySelection] = useState<string>(
    queryParam.city || userLocation.city || '',
  )
  const [showSmallList, setShowSmallList] = useState<boolean>(false)
  const [showCarousels, setShowCarousels] = useState<boolean>(false)
  const [showFullListBt, setShowFullListBt] = useState<boolean>(false)
  const [showMoreLocations, setShowMoreLocations] = useState<boolean>(false)

  const getSelectedCity = (response: HomeRecommendationsResponse = homeResponse.data) =>
    response?.filters?.states.find((state) => state.selected)?.cities.find((c) => c.selected)?.label

  const getSelectedUF = () =>
    homeResponse?.data?.filters?.states.find((state) => state.selected)?.value

  const handleLocationBt = () => {
    if (homeResponse?.data?.filters.states.length === 0) return

    T.handleTagLocation()
    setBsStateParam({ ...bsStateParam, showBottomSheetLocation: true })
  }

  const fetchHomeResponse = async (city: string = citySelection) => {
    const queryToGet = { city, latitude: '', longitude: '', currentCity: userLocation?.city }
    if (userLocation?.latitude) {
      queryToGet.latitude = userLocation.latitude
      queryToGet.longitude = userLocation.longitude
    }
    const res = (await getHomeResponse(
      `${process.env.REACT_APP_API}/home?${queryString.stringify(queryToGet)}`,
    ).catch(window.console.log)) as HomeRecommendationsResponse

    const newCity = getSelectedCity(res)

    setCitySelection(newCity ?? '')

    const newQueryParam = {
      ...queryParam,
      city: newCity,
      name: '',
      cookings: [],
      districts: [],
      days: [],
      mealTimes: [],
      orderMethod: [],
      price: [],
      favorites: false,
      newRestaurant: false,
    }

    if (userLocation?.latitude && !queryParam?.latitude) {
      newQueryParam.latitude = userLocation.latitude
      newQueryParam.longitude = userLocation.longitude
    }
    setQueryParam(newQueryParam)

    setShowCarousels(res?.carousels?.length > 0)
    if (res?.fewRestaurantsList?.length > 0) {
      setShowSmallList(true)
      if (res.fewRestaurantsList.length <= 5) {
        setShowMoreLocations(true)
      } else if (res.fewRestaurantsList.length > 5) {
        setShowFullListBt(true)
      }
    } else {
      setShowSmallList(false)
      setShowMoreLocations(res?.carousels?.length === 0)
      setShowFullListBt(false)
    }
  }

  useEffect(() => {
    fetchHomeResponse()
  }, [])

  if (homeResponse.isError) {
    return (
      <S.Wrapper>
        <Error goBackNative className="errorStyle" />
      </S.Wrapper>
    )
  }

  if (homeResponse.isSuccess) {
    return (
      <S.Wrapper>
        <S.Header>
          <LocationBt
            locationLabel={`${getSelectedCity()} / ${getSelectedUF()}`}
            onClickHandler={handleLocationBt}
          />
          <LocationFilter
            type="home"
            statesAndCities={homeResponse?.data?.filters?.states}
            actualState={getSelectedUF() ?? ''}
            fetchHomeResponse={fetchHomeResponse}
          />
          <Divider color="var(--gray200)" style={{ margin: '0 -2rem' }} />
        </S.Header>

        <S.Content duoGourmetInterCustomer={homeResponse.data?.duoGourmetInterCustomer}>
          <HomeAnnouncements
            banners={homeResponse.data?.topBanners}
            canSubscribe={homeResponse.data?.canSubscribe}
            pessoaFisica={homeResponse.data?.pessoaFisica}
            className="topAnnouncementsStyle"
            bannerTag={{
              ref_figma: '5',
              flow: ETrackEvent.F_HOME_SUB,
              screen: ETrackEvent.S_HOME_SUB,
              subscriber: `${configs.subscriber.duoGourmetInterCustomer}`,
            }}
          />
          {homeResponse.data?.specialSelections.length > 0 && (
            <SpecialSelections
              selections={homeResponse.data?.specialSelections}
              city={queryParam.city}
            />
          )}

          {showCarousels && (
            <S.CarouselList>
              {homeResponse.data?.carousels.map((carousel, index) => (
                <RecommendationCarousel
                  carousel={carousel}
                  duoGourmetInterCustomer={homeResponse?.data?.duoGourmetInterCustomer}
                  city={queryParam.city}
                  key={`${carousel.title}-${index.toString()}`}
                />
              ))}
            </S.CarouselList>
          )}
          {showSmallList && (
            <RecommendationList
              recommendations={{
                title: t('home.ourRecommendations'),
                restaurants: homeResponse.data?.fewRestaurantsList,
              }}
            />
          )}
          {showFullListBt && (
            <S.ListBt>
              <Button
                onClick={() => handleTabChange && handleTabChange(ECurrentMainTab.restaurants)}
                fullWidth
              >
                {t('home.seeFullList')}
              </Button>
            </S.ListBt>
          )}
          {showMoreLocations && (
            <>
              <Divider color="var(--gray100)" borderWidth={8} style={{ margin: '0 -2rem' }} />
              <S.Footer>
                <Text variant="body-1" bold colorWeight={500} className="textStyle">
                  {t('home.duoCountryRange')}
                </Text>
                <Text variant="body-3" className="textStyle">
                  {t('home.duoCityMessage')}
                </Text>
                <Button onClick={handleLocationBt} fullWidth>
                  {t('home.seeOtherRegions')}
                </Button>
              </S.Footer>
            </>
          )}
        </S.Content>
        {homeResponse.data?.bottomBanners.length > 0 && (
          <HomeAnnouncements
            banners={homeResponse.data?.bottomBanners}
            canSubscribe={homeResponse.data?.canSubscribe}
            pessoaFisica={homeResponse.data?.pessoaFisica}
            className="bottomAnnouncementsStyle"
            bannerTag={{
              ref_figma: '5',
              flow: ETrackEvent.F_HOME_SUB,
              screen: ETrackEvent.S_HOME_SUB,
              subscriber: `${configs.subscriber.duoGourmetInterCustomer}`,
            }}
          />
        )}
        {!homeResponse.data?.duoGourmetInterCustomer && (
          <S.SubscribeContainer>
            <Divider color="var(--gray100)" style={{ margin: '0' }} />
            <SubscribeBt
              banners={homeResponse.data?.topBanners}
              canSubscribe={homeResponse.data?.canSubscribe}
              pessoaFisica={homeResponse.data?.pessoaFisica}
              subscriptionButton={homeResponse.data?.subscriptionButton}
            />
          </S.SubscribeContainer>
        )}
      </S.Wrapper>
    )
  }

  return (
    <S.Wrapper>
      <RecommendationsTabShimmer />
    </S.Wrapper>
  )
}

export default RecommendationsTab
