import React, { useState } from 'react'

import { AppContainer } from 'App.styles'
import { useFilters } from 'contexts/FiltersContext'
import { useBSState } from 'contexts/FiltersBottomsheetContext'
import AppBar from 'components/AppBar'
import ListItemWithSelection from 'components/ListItemWithSelection'
import CookingsFilter from 'features/Restaurants/RestaurantsFilters/CookingsFilter'
import DistrictsFilter from 'features/Restaurants/RestaurantsFilters/DistrictsFilter'
import Divider from 'components/Divider'
import { FilterObject } from 'types/Filters'
import { ETrackEvent } from 'enums/ETrackEvent'
import { ECurrentMainTab } from 'enums/EContexts'
import { useRestaurants } from 'contexts/RestaurantsContext'
import { useTranslation } from 'react-i18next'

import { Text } from '@interco/inter-ui/components/Text'
import { Button } from '@interco/inter-ui/components/Button'
import { RouteComponentProps, useNavigate } from '@reach/router'

import * as S from './Filters.styles'
import * as T from './Filters.tags'
import { FilterCategories, FilterProps } from '.'

const Filters = ({ location }: RouteComponentProps) => {
  const navigate = useNavigate()
  const { setShouldRefetch } = useRestaurants()
  const { t } = useTranslation()

  const { queryParam, setQueryParam } = useFilters()
  const { bsStateParam, setBsStateParam } = useBSState()
  const { cookings, districts, days, mealTimes, orderMethod, price } =
    location?.state as FilterProps

  const [filter, setFilter] = useState<FilterCategories>({
    cookings: JSON.parse(JSON.stringify(cookings)),
    districts: JSON.parse(JSON.stringify(districts)),
    days: JSON.parse(JSON.stringify(days)),
    mealTimes: JSON.parse(JSON.stringify(mealTimes)),
    orderMethod: JSON.parse(JSON.stringify(orderMethod)),
    price: JSON.parse(JSON.stringify(price)),
  })

  const onFilter = async () => {
    T.handleTagApplyFilter({ selected_filter: filtersAnalytics() })

    setQueryParam({
      ...queryParam,
      cookings: filter.cookings.filter((d) => d.selected).map((d) => d.value),
      districts: filter.districts.filter((d) => d.selected).map((d) => d.value),
      days: filter.days.filter((d) => d.selected).map((d) => d.value),
      mealTimes: filter.mealTimes.filter((d) => d.selected).map((d) => d.value),
      orderMethod: filter.orderMethod.filter((d) => d.selected).map((d) => d.value),
      price: filter.price.filter((d) => d.selected).map((d) => d.value),
      offset: 0,
    })
    setShouldRefetch(true)
    navigate(`/home/${ECurrentMainTab.restaurants}`)
  }

  const isSelected = () =>
    filter?.cookings?.some((d) => d.selected) ||
    filter?.districts?.some((d) => d.selected) ||
    filter?.days?.some((d) => d.selected) ||
    filter?.mealTimes?.some((d) => d.selected) ||
    filter?.orderMethod?.some((d) => d.selected) ||
    filter?.price?.some((d) => d.selected)

  const clearFilter = async () => {
    if (isSelected()) {
      T.handleTagClearFilter(ETrackEvent.S_FILTERS)

      const clearCookings = filter.cookings?.map((d) => {
        d.selected = false
        return d
      })
      const clearDistricts = filter.districts?.map((d) => {
        d.selected = false
        return d
      })
      const clearDays = filter.days?.map((d) => {
        d.selected = false
        return d
      })
      const clearMealTimes = filter.mealTimes?.map((d) => {
        d.selected = false
        return d
      })
      const clearOrderMethod = filter.orderMethod?.map((d) => {
        d.selected = false
        return d
      })
      const clearPrice = filter.price?.map((d) => {
        d.selected = false
        return d
      })

      setFilter({
        cookings: clearCookings,
        districts: clearDistricts,
        days: clearDays,
        mealTimes: clearMealTimes,
        orderMethod: clearOrderMethod,
        price: clearPrice,
      })
    }
  }

  const handleChange = (key: keyof FilterCategories, index: number) => {
    const updatedSelection = filter[key]
    updatedSelection[index].selected = !updatedSelection[index].selected

    switch (key) {
      case 'days':
        setFilter({ ...filter, days: updatedSelection })
        break
      case 'mealTimes':
        setFilter({ ...filter, mealTimes: updatedSelection })
        break
      case 'orderMethod':
        setFilter({ ...filter, orderMethod: updatedSelection })
        break
      case 'price':
        setFilter({ ...filter, price: updatedSelection })
        break
      default:
        break
    }
  }

  const getSelectedLabels = (obj: FilterObject[]) => obj.map((item) => item.label)
  const getSelectedFilters = (obj: FilterObject[]) => obj.filter((item) => item.selected)
  const getStringOfSelected = (obj: FilterObject[]) =>
    getSelectedLabels(getSelectedFilters(obj)).join(', ')

  const filtersAnalytics = () =>
    Object.values(filter)
      .flatMap((value) => getSelectedLabels(getSelectedFilters(value)))
      .join(', ')

  return (
    <>
      <AppBar name={t('filters.filters')} />
      <AppContainer>
        <S.ContentContainer>
          <div className={isSelected() ? 'two-btn' : 'one-btn'}>
            <ListItemWithSelection
              label={t('filters.culinary')}
              arrow
              selection={getStringOfSelected(filter.cookings)}
              onClick={() => setBsStateParam({ ...bsStateParam, showBottomSheetCooking: true })}
            />
            <CookingsFilter filters={filter} setFilters={setFilter} />
            <Divider style={{ margin: '-0.2rem 0 0.2rem' }} />
            <ListItemWithSelection
              label={t('filters.districts')}
              arrow
              selection={getStringOfSelected(filter.districts)}
              onClick={() => setBsStateParam({ ...bsStateParam, showBottomSheetDistricts: true })}
            />
            <DistrictsFilter filters={filter} setFilters={setFilter} />
            <Divider style={{ margin: '-0.2rem 0 0.2rem' }} />
            <S.SubCategory>
              <S.FilterSubtitle>
                <Text variant="body-3" colorWeight={500} bold>
                  {t('filters.days')}
                </Text>
              </S.FilterSubtitle>
              {filter?.days?.map((e, i) => (
                <S.FilterItem key={e.value.toString()}>
                  <input
                    className="input"
                    type="checkbox"
                    id={e.value}
                    name={e.label}
                    onChange={() => handleChange('days', i)}
                    checked={e.selected}
                  />
                  <label className="label" htmlFor={e.value}>
                    {e.label}
                  </label>
                </S.FilterItem>
              ))}
            </S.SubCategory>
            <Divider type="dashed" />
            <S.SubCategory>
              <S.FilterSubtitle>
                <Text variant="body-3" colorWeight={500} bold>
                  {t('filters.schedule')}
                </Text>
              </S.FilterSubtitle>
              {filter?.mealTimes?.map((e, i) => (
                <S.FilterItem key={e.value.toString()}>
                  <input
                    className="input"
                    type="checkbox"
                    id={e.value}
                    name={e.label}
                    checked={e.selected}
                    onChange={() => handleChange('mealTimes', i)}
                  />
                  <label className="label" htmlFor={e.value}>
                    {e.label}
                  </label>
                </S.FilterItem>
              ))}
            </S.SubCategory>
            <Divider type="dashed" />
            <S.SubCategory>
              <S.FilterSubtitle>
                <Text variant="body-3" colorWeight={500} bold>
                  {t('filters.orderMethod')}
                </Text>
              </S.FilterSubtitle>
              {filter?.orderMethod?.map((e, i) => (
                <S.FilterItem key={e.value.toString()}>
                  <input
                    className="input"
                    type="checkbox"
                    id={e.value}
                    name={e.label}
                    checked={e.selected}
                    onChange={() => handleChange('orderMethod', i)}
                  />
                  <label className="label" htmlFor={e.value}>
                    {e.label}
                  </label>
                </S.FilterItem>
              ))}
            </S.SubCategory>
            <Divider type="dashed" />
            <S.SubCategory>
              <S.FilterSubtitle>
                <Text variant="body-3" colorWeight={500} bold>
                  {t('filters.averagePrice')}
                </Text>
              </S.FilterSubtitle>
              {filter?.price?.map((e, i) => (
                <S.FilterItem key={e.value.toString()}>
                  <input
                    className="input"
                    type="checkbox"
                    id={e.value}
                    name={e.label}
                    checked={e.selected}
                    onChange={() => handleChange('price', i)}
                  />
                  <label className="label" htmlFor={e.value}>
                    {e.label}
                  </label>
                </S.FilterItem>
              ))}
            </S.SubCategory>
          </div>
        </S.ContentContainer>
      </AppContainer>
      <Divider style={{ marginTop: '-0.5rem' }} />
      <S.ButtonsContainer>
        <Button data-testid="avaliation-button" onClick={onFilter} className="button-style">
          {t('filters.applyFilters')}
        </Button>
        {isSelected() && (
          <Button
            variant="secondary"
            data-testid="restaurants-button"
            className="button-style"
            onClick={clearFilter}
          >
            {t('filters.clearFilters')}
          </Button>
        )}
      </S.ButtonsContainer>
    </>
  )
}

export default Filters
