import React, { Fragment, useEffect, useState } from 'react'

import NewRelic from 'utils/NewRelic'
import RestaurantsMap from 'components/RestaurantsMap'
import IconPin from 'assets/icons/location.svg'
import { BottomSheet } from 'components/BottomSheet'
import PriceHank from 'components/PriceHank'
import useAsync from 'hooks/UseAsync'
import { useFilters } from 'contexts/FiltersContext'
import { RestaurantResponse } from 'types/Restaurant'
import useUserLocation from 'hooks/useUserLocation'
import queryString from 'query-string'
import { ApiErrors } from 'types/http/ApiError'
import NotFound from 'components/NotFound'
import Divider from 'components/Divider'
import { WEEK } from 'features/Restaurants/OperationCalendar/OperationCalendar'
import { useConfigs } from 'contexts/ConfigsContext'
import { useTranslation } from 'react-i18next'

import { interWbNavigate } from '@interco/inter-webview-bridge'
import ForkKnifeIcon from '@interco/icons/orangeds/XL/fork-knife'
import ShoppingBagIcon from '@interco/icons/orangeds/XL/shopping-bag'
import { Text } from '@interco/inter-ui/components/Text'
import { Button } from '@interco/inter-ui/components/Button'
import { Skeleton } from '@interco/inter-ui/components/Skeleton'
import { Marker } from '@react-google-maps/api'
import { useNavigate } from '@reach/router'
import { Tag } from '@interco/inter-ui/components/Tag'
import { Grid, GridItem } from '@interco/inter-ui/components/Grid'
import { Alert } from '@interco/inter-ui/components/Alert'

import {
  Container,
  ContainerLoading,
  ButtonContent,
  RestaurantAddress,
  ContainerCookingContent,
  CookingContent,
  TagsContainer,
  ScheduleContainer,
  ScheduleHeader,
  SeeAllRestBt,
  DeliveryAlert,
  TagSpace,
} from './MapFilter.styles'
import { MapApiResponse, MapFilterProps, WEEKDAY } from './MapFilter.types'
import { handleTagClose, handleTagRestaurant, handleTagRoute } from './MapFilterTags'

const IconPinRestaurant = { url: IconPin } as google.maps.Icon

const RestaurantsMapFilter = ({ clearFilters }: MapFilterProps) => {
  const navigate = useNavigate()
  const userLocation = useUserLocation()
  const userCoordinates = {
    lat: parseFloat(userLocation?.latitude),
    lng: parseFloat(userLocation?.longitude),
  }

  const { queryParam, setQueryParam } = useFilters()
  const { configs } = useConfigs()
  const { t } = useTranslation()
  const [mapResponse, getMapResponse] = useAsync<MapApiResponse>('get')
  const [showBottomSheet, setShowBottomSheet] = useState<boolean>(false)
  const [restaurantDetails, getRestaurantDetails] = useAsync<RestaurantResponse>('get')

  const [loadingBottomSheet, setLoadingBottomSheet] = useState<boolean>(true)
  const [loadingMap, setLoadingMap] = useState<boolean>(true)

  const iconProps = { width: 18, height: 18, color: 'var(--gray500)' }

  const dayOfWeek = WEEKDAY[new Date().getDay() as unknown as keyof typeof WEEKDAY]
  const enumDay = WEEK[dayOfWeek as unknown as keyof typeof WEEK].toLocaleLowerCase()

  const fetchRestaurants = async () => {
    try {
      setLoadingMap(true)
      await getMapResponse(
        `${process.env.REACT_APP_API}/map?${queryString.stringify({
          ...queryParam,
        })}`,
      )
      setLoadingMap(false)
    } catch (error) {
      setLoadingMap(false)
      const err = error as ApiErrors
      err.message +=
        ' ---- ' +
        `${process.env.REACT_APP_API}/map?${queryString.stringify({
          ...queryParam,
        })}`
      NewRelic.noticeError(err)
    }
  }

  useEffect(() => {
    fetchRestaurants()
  }, [queryParam])

  useEffect(() => {
    setQueryParam({ ...queryParam, useNow: false })
  }, [])

  const fetchRestaurantDetails = React.useCallback(
    async (restaurantId: string) => {
      try {
        await getRestaurantDetails(`${process.env.REACT_APP_API}/restaurants/${restaurantId}`)
        setLoadingBottomSheet(false)
      } catch (error) {
        console.log(error)
      }
    },
    [restaurantDetails],
  )

  const handleBSClose = () => {
    handleTagClose()
    setShowBottomSheet(false)
  }

  const handleClickMarker = (restaurantId: string) => {
    setLoadingBottomSheet(true)
    setShowBottomSheet(true)
    fetchRestaurantDetails(restaurantId)
  }

  const isDuoGourmetInterCustomer = () => restaurantDetails?.data.duoGourmetInterCustomer

  const getBoundsCoordinates = () =>
    mapResponse.data?.restaurants.map(({ coordinates }) => ({
      lat: coordinates.latitude,
      lng: coordinates.longitude,
    }))

  const bsContent = (
    <>
      {loadingBottomSheet ? (
        <ContainerLoading>
          <Button variant="link" isLoading />
        </ContainerLoading>
      ) : (
        <>
          <Text variant="headline-h3" semiBold>
            {restaurantDetails.data?.restaurant.name}
          </Text>
          <RestaurantAddress>
            <Text variant="caption-1">
              {restaurantDetails.data?.restaurant?.address}
              <br />
              {restaurantDetails.data?.restaurant?.location}
            </Text>
          </RestaurantAddress>
          <ContainerCookingContent>
            <CookingContent>
              <ForkKnifeIcon {...iconProps} style={{ marginRight: '0.5rem' }} />
              <Text variant="caption-1">{restaurantDetails.data?.restaurant?.cooking}</Text>
              <span style={{ color: 'var(--gray200)', marginLeft: '3px', marginRight: '3px' }}>
                •
              </span>
              <PriceHank price={restaurantDetails.data?.restaurant?.price} />
            </CookingContent>
            <CookingContent>
              <ShoppingBagIcon {...iconProps} style={{ marginRight: '0.5rem' }} />
              {restaurantDetails.data?.choices.map((choice, idx, arr) => (
                <div key={`${choice}-${idx.toString()}`}>
                  <Text color="typography" variant="caption-1">
                    {choice.title}
                  </Text>
                  {idx < arr.length - 1 && (
                    <span
                      style={{ color: 'var(--gray200)', marginLeft: '3px', marginRight: '3px' }}
                    >
                      •
                    </span>
                  )}
                </div>
              ))}
            </CookingContent>
          </ContainerCookingContent>
          {isDuoGourmetInterCustomer() && (
            <TagsContainer>
              {restaurantDetails?.data?.useNow && (
                <TagSpace>
                  <Tag color="var(--success100)" colorText="var(--success500)">
                    {t('restaurantsDetails.useNow')}
                  </Tag>
                </TagSpace>
              )}
              {!restaurantDetails?.data?.available && (
                <TagSpace>
                  <Tag color="var(--gray100)" colorText="var(--gray400)">
                    {t('restaurantsDetails.notAvailable')}
                  </Tag>
                </TagSpace>
              )}
              {restaurantDetails?.data?.newRestaurant && (
                <TagSpace>
                  <Tag theme="yellow">{t('restaurantsDetails.new')}</Tag>
                </TagSpace>
              )}
              {restaurantDetails?.data?.deliveryUseNow && (
                <TagSpace>
                  <Tag color="var(--primary100)" colorText="var(--primary500)">
                    {t('restaurantsDetails.orderNow')}
                  </Tag>
                </TagSpace>
              )}
              {restaurantDetails?.data?.takeOutUseNow && (
                <TagSpace>
                  <Tag color="var(--turquoise100)" colorText="var(--turquoise500)">
                    {t('restaurantsDetails.takeOutNow')}
                  </Tag>
                </TagSpace>
              )}
            </TagsContainer>
          )}
          <ScheduleContainer>
            <Grid templateRows="repeat(1, 1fr)" templateColumns="repeat(3, 1fr)" gap="5px">
              <GridItem>
                <Text variant="caption-1" bold>
                  {t('calendar.today')}, {enumDay}
                </Text>
              </GridItem>
              <GridItem>
                <ScheduleHeader>
                  <Text variant="caption-1" bold>
                    {t('calendar.lunch')}
                  </Text>
                </ScheduleHeader>
              </GridItem>
              <GridItem>
                <ScheduleHeader>
                  <Text variant="caption-1" bold>
                    {t('calendar.dinner')}
                  </Text>
                </ScheduleHeader>
              </GridItem>
              <GridItem column="span 3">
                <Divider style={{ margin: 0 }} />
              </GridItem>
            </Grid>
            <Grid
              templateRows={`repeat(${restaurantDetails.data?.restaurant?.nextSchedule.length}, 1fr)`}
              templateColumns="repeat(3, 1fr)"
              gap="6px"
              style={{ marginTop: '0.3rem' }}
            >
              {restaurantDetails?.data?.restaurant?.nextSchedule.map((schedule) => (
                <Fragment key={schedule.modality}>
                  <GridItem>
                    <Text variant="caption-1" bold colorWeight={500}>
                      {schedule.modality}
                    </Text>
                  </GridItem>
                  <GridItem>
                    <ScheduleHeader>
                      <Text variant="caption-1" bold colorWeight={500}>
                        {schedule.lunchSchedule}
                      </Text>
                    </ScheduleHeader>
                  </GridItem>
                  <GridItem>
                    <ScheduleHeader>
                      <Text variant="caption-1" bold colorWeight={500}>
                        {schedule.dinnerSchedule}
                      </Text>
                    </ScheduleHeader>
                  </GridItem>
                </Fragment>
              ))}
            </Grid>
          </ScheduleContainer>
          {restaurantDetails.data?.restaurant?.delivery && (
            <DeliveryAlert style={{ margin: '1rem 0' }}>
              <Alert type="grayscale">{t('restaurantsDetails.checkDeliveryHours')}</Alert>
            </DeliveryAlert>
          )}
          <ButtonContent>
            <Button
              style={{ width: '100%' }}
              variant="secondary"
              onClick={async () => {
                handleTagRoute({
                  restaurant: restaurantDetails.data?.restaurant?.name,
                  plan_name: configs.subscriber.planName,
                  plan_value: configs.subscriber.planValue,
                })
                try {
                  await interWbNavigate.openLocationMap(
                    restaurantDetails.data?.restaurant?.coordinates?.latitude,
                    restaurantDetails.data?.restaurant?.coordinates?.longitude,
                  )
                } catch (error) {
                  window.console.log(error)
                }
              }}
            >
              {t('restaurantsDetails.defineRoute')}
            </Button>
            <Button
              style={{ width: '100%' }}
              onClick={async () => {
                handleTagRestaurant({
                  restaurant: restaurantDetails.data?.restaurant?.name,
                  plan_name: configs.subscriber.planName,
                  plan_value: configs.subscriber.planValue,
                })
                navigate(`/restaurants/${restaurantDetails.data?.restaurant?.id}`)
              }}
            >
              {t('restaurantsDetails.goToRestaurantsPage')}
            </Button>
          </ButtonContent>
        </>
      )}
    </>
  )

  if (mapResponse.isSuccess && mapResponse.data.restaurants.length === 0) {
    return (
      <>
        <NotFound title={t('utils.noSearchResult')} description={t('home.redoSearch')} />
        <SeeAllRestBt>
          <Button
            variant="primary"
            onClick={clearFilters}
            style={{ width: 'calc(100% - 3rem)', position: 'fixed', bottom: 20, zIndex: 1 }}
          >
            {t('home.seeAllRestaurants')}
          </Button>
        </SeeAllRestBt>
      </>
    )
  }

  return (
    <Container>
      {loadingMap ? (
        <Skeleton width="100%" height="100%" />
      ) : (
        <RestaurantsMap
          userCoordinates={userCoordinates}
          boundsCoordinates={getBoundsCoordinates()}
        >
          {mapResponse.data?.restaurants?.map(({ id, coordinates }) => (
            <Marker
              position={{ lat: coordinates.latitude, lng: coordinates.longitude }}
              icon={IconPinRestaurant}
              key={id}
              onClick={() => handleClickMarker(id)}
            />
          ))}
        </RestaurantsMap>
      )}
      <BottomSheet
        showBottomSheet={showBottomSheet}
        onDismiss={handleBSClose}
        headerLabel={t('filters.map')}
        headerBtLabel={t('utils.closeBt')}
        headerBtHandler={handleBSClose}
        bsContent={bsContent}
      />
    </Container>
  )
}
export default RestaurantsMapFilter
