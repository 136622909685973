import React, { useCallback } from 'react'

import { useTranslation } from 'react-i18next'

import ChevronUpIcon from '@interco/icons/orangeds/XL/chevron-up'
import ChevronDownIcon from '@interco/icons/orangeds/XL/chevron-down'
import { Text } from '@interco/inter-ui/components/Text'
import { Accordion } from '@interco/inter-ui/components/Accordion'

import * as S from './ExperienceRules.styles'

export interface ExperienceRulesProps {
  content: string
  analyticsTag: () => void
}

const iconProps = { height: 20, width: 20, color: 'var(--primary500)' }

export const ExperienceRules = ({ content = '', analyticsTag }: ExperienceRulesProps) => {
  const [expanded, setExpanded] = React.useState(true)
  const { t } = useTranslation()

  const handleClick = () => {
    analyticsTag()
    setExpanded(!expanded)
  }

  const CustomLabel = useCallback(
    () => (
      <S.CustomLabelContainer>
        <Text variant="body-3" bold colorWeight={500}>
          {t('experiencesDetails.usageRules')}
        </Text>
        <S.ToggleIcon>
          {expanded ? <ChevronUpIcon {...iconProps} /> : <ChevronDownIcon {...iconProps} />}
        </S.ToggleIcon>
      </S.CustomLabelContainer>
    ),
    [expanded],
  )

  return (
    <Accordion
      labelComponent={<CustomLabel />}
      expanded={expanded}
      onClick={handleClick}
      data-testid="accordion-click"
    >
      <S.Rules>
        <Text variant="caption-1">
          <div
            dangerouslySetInnerHTML={{
              __html: content,
            }}
            className="text-style"
          />
        </Text>
      </S.Rules>
    </Accordion>
  )
}

export default ExperienceRules
