import React, { useEffect } from 'react'

import ImgSuccess from 'assets/imgs/blackcard/success.svg'
import { AppContainer } from 'App.styles'
import { useTranslation } from 'react-i18next'

import { interWbNavigate } from '@interco/inter-webview-bridge'
import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'
import { RouteComponentProps, useNavigate } from '@reach/router'

import { ButtonContent, PersonasImgContent } from './CompleteSubscription.styles'

const CompleteSubscription = (_: RouteComponentProps) => {
  const [isDeliveryAccess, setIsDeliveryAccess] = React.useState(false)
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleNavigate = async () => {
    isDeliveryAccess
      ? await interWbNavigate.openDeepLink('bancointer://delivery/revisaoPedido')
      : navigate(`/onboarding`)
  }

  useEffect(() => {
    const deliveryAccessDateUnix = localStorage.getItem('DUOGOURMET.deliveryAccessDate')

    let canSendToDelivery = false
    if (deliveryAccessDateUnix) {
      const deliveryAccessDate = new Date(Number(deliveryAccessDateUnix) * 1000)
      const isAccess = localStorage.getItem('DUOGOURMET.isDeliveryAccess')
      canSendToDelivery = !!(isAccess && getMinutesBetweenDates(deliveryAccessDate, new Date()))
    }

    setIsDeliveryAccess(canSendToDelivery)

    function getMinutesBetweenDates(startDate: Date, endDate: Date) {
      const diff = endDate.getTime() - startDate.getTime()
      return diff / 60000
    }
  }, [])

  return (
    <>
      <AppContainer style={{ textAlign: 'center', paddingTop: 100 }}>
        <PersonasImgContent>
          <img src={ImgSuccess} alt="" />
        </PersonasImgContent>
        <div>
          <Text variant="headline-h1" semiBold>
            {t('subscription.subscriptionDone')}
          </Text>
        </div>
        <div style={{ marginTop: '0.5rem' }}>
          <Text variant="body-3">{t('subscription.subscriptionDoneMessage')}</Text>
        </div>
      </AppContainer>
      <ButtonContent>
        <Button data-testid="onboarding-button" onClick={handleNavigate} style={{ width: '100%' }}>
          {isDeliveryAccess
            ? t('subscription.backToDelivery')
            : t('subscription.startUsingDuoGourmet')}
        </Button>
      </ButtonContent>
    </>
  )
}

export default CompleteSubscription
