import React, { useState, useEffect, useCallback, useRef } from 'react'

import 'react-lazy-load-image-component/src/effects/blur.css'
import queryString from 'query-string'
import { useDebouncedCallback } from 'use-debounce'
import InfiniteScroll from 'react-infinite-scroll-component'
import NewRelic from 'utils/NewRelic'
import { IWbUserLocation } from 'hooks/useUserLocation'
import NotFound from 'components/NotFound'
import Error from 'components/Error'
import Divider from 'components/Divider'
import BackToTopBt from 'features/Home/HomeButtons/BackToTopBt'
import SubscribeBt from 'features/Home/HomeButtons/SubscribeBt'
import HomeAnnouncements from 'features/Home/HomeAnnouncements/HomeAnnouncements'
import HomeAnnouncementsShimmer from 'features/Home/HomeAnnouncements/HomeAnnouncementsShimmer'
import InputSearch from 'components/InputSearch'
import LocationBt from 'components/LocationBt/LocationBt'
import RestaurantCard from 'components/RestaurantCard/RestaurantCard'
import { ApiErrors } from 'types/http/ApiError'
import { useBSState } from 'contexts/FiltersBottomsheetContext'
import { useConfigs } from 'contexts/ConfigsContext'
import { useFilters, QueryParamRestaurant } from 'contexts/FiltersContext'
import { ETrackEvent } from 'enums/ETrackEvent'
import RestaurantsMapFilter from 'features/Restaurants/RestaurantsFilters/MapFilter/MapFilter'
import MapFloatingBt from 'features/Home/HomeButtons/MapFloatingBt'
import { getParamsFromURL } from 'utils/TagsUtils'
import RestaurantsFilters from 'features/Restaurants/RestaurantsFilters/RestaurantsFilters'
import LocationFilter from 'components/LocationFilter/LocationFilter'
import { useRestaurants } from 'contexts/RestaurantsContext'
import { useTranslation } from 'react-i18next'

import { Button } from '@interco/inter-ui/components/Button'

import { ShimmerBody, ShimmerHeader } from './RestaurantsTabShimmer'
import * as S from './RestaurantsTab.styles'
import * as T from './RestaurantsTab.tags'
import { TabsProps } from '../Home.types'

const RestaurantsTab = ({ userLocation }: TabsProps) => {
  const { t } = useTranslation()
  const { configs } = useConfigs()
  const { queryParam, setQueryParam } = useFilters()
  const {
    response,
    data,
    restaurants,
    setRestaurants,
    getRestaurants,
    isError,
    isSuccess,
    shouldRefetch,
    setShouldRefetch,
    offsetScroll,
    setOffsetScroll,
  } = useRestaurants()

  const { bsStateParam, setBsStateParam } = useBSState()

  const [loadingShimmerHeader, setLoadingShimmerHeader] = useState<boolean>(true)
  const [loadingShimmerBody, setLoadingShimmerBody] = useState<boolean>(true)
  const [inputSearchText, setInputSearchText] = useState<string>('')
  const [scrollId, setScrollId] = useState<string>('')

  const restTotal = useRef(0)
  const ref = useRef(0)

  const getSelectedCity = () =>
    data?.filters?.states.find((state) => state.selected)?.cities.find((c) => c.selected)?.label

  const getSelectedUF = () => data?.filters?.states.find((state) => state.selected)?.value

  const handleLocationBt = () => {
    if (data?.filters.states.length === 0) return

    T.handleTagLocation({
      plan_name: configs?.subscriber?.planName,
      plan_value: configs?.subscriber?.planValue,
      subscriber: `${configs?.subscriber?.duoGourmetInterCustomer}`,
    })

    setBsStateParam({ ...bsStateParam, showBottomSheetLocation: true })
  }

  const fetchRestaurants = async (newQueryParam: QueryParamRestaurant = queryParam) => {
    restTotal.current = 0
    ref.current = 0

    setQueryParam(newQueryParam)
    setLoadingShimmerBody(true)
    try {
      const res = await getRestaurants(
        `${process.env.REACT_APP_API}/listing?${queryString.stringify({
          ...newQueryParam,
          offset: ref.current,
        })}`,
      )

      setRestaurants(res.restaurants)
      setLoadingShimmerBody(false)
      setLoadingShimmerHeader(false)
      setScrollId(res.page.scrollId)
      setShouldRefetch(false)
    } catch (error) {
      const err = error as ApiErrors
      NewRelic.noticeError(err)
    }
  }

  const next = useCallback(async () => {
    try {
      ref.current += queryParam.size
      const res = await getRestaurants(
        `${process.env.REACT_APP_API}/listing?${queryString.stringify({
          ...queryParam,
          offset: ref.current,
          scrollId,
        })}`,
        undefined,
      )
      setRestaurants((d) => [...d, ...res.restaurants])
      setScrollId(res.page.scrollId)
      restTotal.current += restaurants.length
    } catch (error) {
      const err = error as ApiErrors
      NewRelic.noticeError(err)
    }
  }, [getRestaurants, queryParam, scrollId])

  const search = useDebouncedCallback(async (value: string) => {
    value = value.trim()
    if (!value) {
      delete queryParam.name
      const newQueryParam = { ...queryParam }
      fetchRestaurants(newQueryParam)
      return
    }
    if (value.length < 3) return
    T.handleTagSearch({
      search_term: value,
      subscriber: configs.subscriber.duoGourmetInterCustomer.toString(),
    })
    const newQueryParam = { ...queryParam, name: value }
    fetchRestaurants(newQueryParam)
  }, 500)

  const clearFilters = () => {
    const newQueryParam = {
      ...queryParam,
      name: '',
      cookings: [],
      districts: [],
      days: [],
      price: [],
      mealTimes: [],
      orderMethod: [],
      favorites: false,
      newRestaurant: false,
      useNow: false,
    }

    setInputSearchText('')
    fetchRestaurants(newQueryParam)
  }

  const isFiltersSelected =
    data?.filters?.cookings.some((d) => d.selected) ||
    data?.filters?.districts.some((d) => d.selected) ||
    data?.filters?.days.some((d) => d.selected) ||
    data?.filters?.mealTimes.some((d) => d.selected) ||
    data?.filters?.orderMethod.some((d) => d.selected) ||
    data?.filters?.price.some((d) => d.selected)

  const SubscriberInter = data?.duoGourmetInterCustomer

  const noSearchResult = () =>
    isSuccess && (!data?.restaurants || data?.restaurants?.length === 0) && restaurants.length === 0

  const hasActiveFilter = () => queryParam.name || isFiltersSelected || queryParam.newRestaurant

  const formatQueryParam = (currentLocation?: IWbUserLocation) => {
    const { filterParams } = getParamsFromURL()

    const newQueryParams = {
      ...queryParam,
    }

    if (currentLocation) {
      newQueryParams.latitude = currentLocation?.latitude
      newQueryParams.longitude = currentLocation?.longitude
      newQueryParams.city = currentLocation?.city
    }

    if (filterParams) {
      if (filterParams.city) {
        newQueryParams.city = filterParams.city
      }
      if (filterParams.districts) {
        newQueryParams.districts = filterParams.districts.split(',')
      }

      if (filterParams.name) {
        newQueryParams.name = filterParams.name
      }

      if (filterParams.cookings) {
        newQueryParams.cookings = filterParams.cookings.split(',')
      }
      if (filterParams.days) {
        newQueryParams.days = filterParams.days.split(',')
      }
      if (filterParams.mealTimes) {
        newQueryParams.mealTimes = filterParams.mealTimes.split(',')
      }
      if (filterParams.orderMethod) {
        newQueryParams.orderMethod = filterParams.orderMethod.split(',')
      }
      if (filterParams.price) {
        newQueryParams.price = filterParams.price.split(',')
      }

      if (filterParams.favorites && filterParams.favorites === 'true') {
        newQueryParams.favorites = true
      }
      if (filterParams.useNow && filterParams.useNow === 'true') {
        newQueryParams.useNow = true
      }
      if (filterParams.newRestaurant && filterParams.newRestaurant === 'true') {
        newQueryParams.newRestaurant = true
      }
      if (filterParams.map && filterParams.map === 'true') {
        setBsStateParam({ ...bsStateParam, showMapFilter: true })
      }
    }
    return newQueryParams
  }

  useEffect(() => {
    if (queryParam.name) {
      setInputSearchText(queryParam.name)
      setBsStateParam({ ...bsStateParam })
    }
  }, [queryParam.name])

  useEffect(() => {
    async function fetchData() {
      try {
        let newQueryParams = {}
        const hasLocationPermission = configs.permissionLocation.value

        if (!hasLocationPermission || queryParam?.latitude || queryParam?.city) {
          newQueryParams = { ...queryParam }
        } else {
          newQueryParams = formatQueryParam({ ...userLocation })
        }

        fetchRestaurants(newQueryParams as QueryParamRestaurant)
      } catch (error) {
        NewRelic.noticeError(error as ApiErrors)

        const newQueryParams = formatQueryParam()
        fetchRestaurants(newQueryParams)
      }
    }

    if (!restaurants?.length || shouldRefetch) {
      setLoadingShimmerBody(true)
      setLoadingShimmerHeader(true)

      T.handleTagLoad()
      fetchData()
    } else {
      setLoadingShimmerBody(false)
      setLoadingShimmerHeader(false)
    }
  }, [])

  useEffect(() => {
    const defineScroll = (event: MouseEvent) => {
      setOffsetScroll(event.pageY - event.clientY)
    }
    window.addEventListener('click', defineScroll)

    setTimeout(() => {
      window.scrollTo({ top: offsetScroll })
    }, 1)

    return () => {
      window.removeEventListener('click', defineScroll)
    }
  }, [])
  if (isError) {
    return (
      <S.Wrapper>
        <Error goBackNative className="errorStyle" />
      </S.Wrapper>
    )
  }

  return (
    <S.Wrapper>
      {loadingShimmerHeader ? (
        <ShimmerHeader />
      ) : (
        <S.Header>
          <InputSearch
            searchText={inputSearchText}
            setSearchText={setInputSearchText}
            search={search}
          />
          <LocationBt
            locationLabel={`${getSelectedCity()} / ${getSelectedUF()}`}
            onClickHandler={handleLocationBt}
          />
          <LocationFilter
            type="restaurant"
            statesAndCities={data?.filters?.states}
            actualState={getSelectedUF() ?? ''}
            fetchRestaurants={fetchRestaurants}
            setSearchRestaurantsText={setInputSearchText}
            setLoadingShimmerHeader={setLoadingShimmerHeader}
          />
          <Divider color="var(--gray200)" className="dividerStyle" />
          <RestaurantsFilters
            SubscriberInter={SubscriberInter}
            fetchRestaurants={fetchRestaurants}
            isFiltersSelected={isFiltersSelected}
            restaurants={response}
            city={getSelectedCity() ?? ''}
            className="filterStyle"
          />
        </S.Header>
      )}
      {loadingShimmerBody && !bsStateParam?.showMapFilter && <ShimmerBody />}
      <S.Content>
        {noSearchResult() && hasActiveFilter() && (
          <>
            <NotFound title={t('utils.noSearchResult')} description={t('home.redoSearch')} />
            <Button
              variant="primary"
              onClick={clearFilters}
              style={{ width: 'calc(100% - 3rem)', position: 'fixed', bottom: 20, zIndex: 1 }}
            >
              {t('home.seeAllRestaurants')}
            </Button>
          </>
        )}
        {noSearchResult() && !hasActiveFilter() && queryParam.favorites && (
          <NotFound title={t('utils.noFavorites')} description={t('utils.noFavoritesMessage')} />
        )}
        {!noSearchResult() &&
          !hasActiveFilter() &&
          !bsStateParam?.showMapFilter &&
          !queryParam.favorites && (
            <>
              {loadingShimmerBody ? (
                <HomeAnnouncementsShimmer />
              ) : (
                <HomeAnnouncements
                  blackCard={data?.blackCard}
                  banners={data?.banners}
                  canSubscribe={data?.canSubscribe}
                  pessoaFisica={data?.pessoaFisica}
                  bannerTag={{
                    ref_figma: '5',
                    flow: ETrackEvent.F_HOME_SUB,
                    screen: ETrackEvent.S_HOME_SUB,
                    subscriber: `${configs.subscriber.duoGourmetInterCustomer}`,
                  }}
                />
              )}
            </>
          )}
        {restaurants.length > 0 && !bsStateParam?.showMapFilter && !loadingShimmerBody && (
          <InfiniteScroll
            dataLength={
              data?.restaurants?.length > 0 ? restaurants.length + 10 : restaurants.length
            }
            next={next}
            hasMore={data?.restaurants?.length > 0}
            loader={<h4 style={{ marginTop: 10 }}>{t('utils.loading')}</h4>}
          >
            {restaurants?.map((restaurant) => (
              <RestaurantCard
                key={`card_${restaurant.id}}`}
                restaurant={restaurant}
                flow={ETrackEvent.F_USE_BENEFIT}
                screen={ETrackEvent.S_RESTAURANTS_LIST}
              />
            ))}
          </InfiniteScroll>
        )}
      </S.Content>

      {SubscriberInter && !bsStateParam?.showMapFilter && !loadingShimmerHeader && <BackToTopBt />}
      {!noSearchResult() && bsStateParam?.showMapFilter && (
        <RestaurantsMapFilter clearFilters={clearFilters} />
      )}
      {!SubscriberInter && !loadingShimmerHeader && (
        <>
          <Divider color="var(--gray100)" style={{ margin: '0' }} />
          <SubscribeBt
            banners={data?.banners}
            canSubscribe={data?.canSubscribe}
            pessoaFisica={data?.pessoaFisica}
            subscriptionButton={data?.subscriptionButton}
          />
        </>
      )}
      {!loadingShimmerBody && !noSearchResult() && <MapFloatingBt />}
    </S.Wrapper>
  )
}

export default RestaurantsTab
