import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
`

export const CenterIconContainer = styled.div`
  width: 48px;
  height: 48px;
  background-color: white;
  position: absolute;
  top: 32px;
  right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--primary300);
`

export const MapStyles = {
  width: '100%',
  height: '100%',
}
