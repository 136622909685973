import React, { useEffect } from 'react'

import './App.css'
import 'animate.css'
import 'react-spring-bottom-sheet/dist/style.css'

import Routes from 'Routes'
import { GlobalStyle } from 'App.styles'
import FiltersProvider from 'contexts/FiltersContext'
import ConfigsProvider from 'contexts/ConfigsContext'
import { EEnvironment } from 'enums/EEnvironment'
import smoothscroll from 'smoothscroll-polyfill'
import { ApiErrors } from 'types/http/ApiError'
import NewRelic from 'utils/NewRelic'
import FiltersBSProvider from 'contexts/FiltersBottomsheetContext'
import RestaurantsProvider from 'contexts/RestaurantsContext'
import { I18nextProvider } from 'react-i18next'

import InterWebviewBridge, { interWbSession } from '@interco/inter-webview-bridge'
import { initTheme, Theme } from '@interco/inter-ui'
import { SnackBarProvider } from '@interco/inter-ui/components/SnackBar'
import { LocationProvider } from '@reach/router'

import duoGourmetInstance from './utils/i18n'

smoothscroll.polyfill()

export const bridgeNative = InterWebviewBridge.getInstance()

const App: React.FC = () => {
  const getDarkModeInfo = async () => {
    const appInfo = await interWbSession.getAppInfo()
    initTheme(Theme.PF, appInfo?.isDarkMode)
  }

  useEffect(() => {
    try {
      getDarkModeInfo()

      if (
        process.env.REACT_APP_ENV === EEnvironment.DEVELOPMENT ||
        process.env.REACT_APP_ENV === EEnvironment.STAGING
      ) {
        bridgeNative.setDebug(true)
      }

      interWbSession.editToolbar('', false).catch(window.console.error)
    } catch (error) {
      const err = error as ApiErrors
      NewRelic.noticeError(err)
    }
  }, [])

  return (
    <I18nextProvider i18n={duoGourmetInstance}>
      <FiltersBSProvider>
        <ConfigsProvider>
          <SnackBarProvider>
            <FiltersProvider>
              <RestaurantsProvider>
                <LocationProvider>
                  <GlobalStyle />
                  <Routes />
                </LocationProvider>
              </RestaurantsProvider>
            </FiltersProvider>
          </SnackBarProvider>
        </ConfigsProvider>
      </FiltersBSProvider>
    </I18nextProvider>
  )
}

export default App
