export enum ETrackEvent {
  // geral
  T_HEADER = 'HEADER',
  T_SEARCH_TERM = 'SEARCH_TERM',
  T_WIDGET_CROSS = 'WIDGET_CROSS',
  T_BANNER = 'BANNER',
  S_ERROR = 'tela de erro',

  // duo
  T_PRODUCT = 'duo gourmet',
  T_DUO_GOURMET_FLOW = 'DUO_GOURMET_FLUXO',
  T_DUO_GOURMET_ACCESS = 'DUO_GOURMET_ACESSO',
  T_DUO_GOURMET_SUCCESS = 'DUO_GOURMET_SUCESSO',

  // home
  F_HOME_SUB = 'home',
  S_HOME_SUB = 'home duo gourmet',
  S_COLLECTION_LIST = 'tela conferir todos',

  F_HOME_VISITOR = 'fluxo não assinante',
  S_HOME_VISITOR = 'home visitante',

  // onboarding
  F_ONBOARDING = 'onboarding',

  // landing page
  S_LANDING_PAGE = 'lp duo gourmet',

  // restaurantes
  S_RESTAURANTS_LIST = 'lista de restaurantes',
  S_RESTAURANT_DETAILS = 'página restaurante',

  // experiencias
  F_EXPERIENCES = 'experiences',
  S_EXPERIENCES_LIST = 'lista de experiencias',
  S_EXPERIENCE_DETAILS = 'pagina do evento',
  S_EXPERIENCES_RECORDS = 'histórico ingressos',

  // menu usuário
  F_USE_HISTORY = 'utilizações e histórico',
  S_MENU_USER = 'menu duo gourmet',

  // uso benefício
  F_USE_BENEFIT = 'uso benefício',
  S_BENEFIT_VALIDATED = 'código gerado',
  S_USE_RATING = 'avaliação da utilização',
  S_RATING_SUCCESS = 'avaliação sucesso',
  S_RATING_ERROR = 'avaliação erro',
  S_BS_USE_CONFIRM = 'bottomsheet confirmação benefício restaurante com restrição',

  // assinatura e pagamento
  F_SUBSCRIPTION = 'fluxo de assinatura',
  S_SIGNATURE_PAYMENT = 'assinatura e pagamento',
  S_SIG_REACTIVATED = 'assinatura reativada',
  F_SIG_REACTIVATION = 'retomar assinatura',
  F_SIG_CANCELATION = 'cancelamento',
  S_SIG_CANCELATION = 'cancelamento duo gourmet',
  F_PENDING_PAYMENT = 'tentativa de cobrança',
  S_PENDING_PAYMENT_ERROR = 'erro assinatura pendente',
  S_PENDING_PAYMENT_SUCCESS = 'processamento assinatura pendente',

  // filtros
  F_MAP_FILTER = 'filtro mapa',
  S_MAP_FILTER = 'filtro mapa selecionado',
  F_CHANGE_STATE_CITY = 'mudança de endereços',
  S_CHANGE_STATE_CITY = 'bottomsheet busca',
  S_CITY_FILTER = 'filtro cidades',
  F_SEARCH_FILTER = 'filtro de busca',
  S_FILTERS = 'filtros selecionados',
  S_FILTERS_CATEGORY = 'filtros culinaria',
  S_FILTERS_DISTRICTS = 'filtros bairro',
  S_FILTERS_STATES = 'filtros estados',

  // indique e ganhe
  S_REFER_WIN = 'indique e ganhe',

  // utilizações
  S_UTILIZATION = 'histórico duo gourmet',

  // modalities
  S_MODALITY = 'jeito de pedir',
  S_BS_PRESENTIAL = 'bottomsheet presencial',

  // blackcard
  F_BLACKCARD = 'solicitação de cartão black',
  S_BLACKCARD_ONBOARDING = 'onboarding inter black',
  S_BLACKCARD_STEP = 'solicitação de cartão black - passo ',
  S_BS_BLACKCARD = 'bottomsheet confirmação dados cartão black',
}
