import React, { useEffect, useRef, useState } from 'react'

import AppBar from 'components/AppBar'
import { bridgeNative } from 'App'
import useAsync from 'hooks/UseAsync'
import ListIconText from 'components/ListIconText/ListIconText'
import Divider from 'components/Divider'
import { ICampaignDetail } from 'types/Campaign'
import { useTranslation } from 'react-i18next'

import { interWbNavigate } from '@interco/inter-webview-bridge'
import { Button } from '@interco/inter-ui/components/Button'
import { Skeleton } from '@interco/inter-ui/components/Skeleton'
import { Text } from '@interco/inter-ui/components/Text'
import { navigate, RouteComponentProps } from '@reach/router'

import { BottomContent, Container, UpperContent, Wrap } from './CampaignDetail.style'

interface ILocationSort {
  sort: string
}

const CampaignDetail = ({ location }: RouteComponentProps) => {
  const [loadShimmer, setLoadShimmer] = useState<boolean>(true)
  const [campaignData, setCampaignData] = useState<ICampaignDetail>()
  const [, getCampaign] = useAsync<ICampaignDetail>('get')
  const [, registerCampaign] = useAsync('post')
  const { sort } = location?.state as ILocationSort
  const isInitialMount = useRef<boolean>(true)
  const { t } = useTranslation()

  useEffect(() => {
    if (isInitialMount.current && window.history.length === 1) {
      isInitialMount.current = false
    }

    async function fetchCampaign() {
      const res = await getCampaign(`${process.env.REACT_APP_API}/event?`, undefined, {
        event: sort,
      })
      setLoadShimmer(false)
      setCampaignData(res)
    }

    fetchCampaign().catch((error) => {
      setLoadShimmer(false)
      window.console.error(error)
    })
  }, [])

  const handleToTerms = () => {
    bridgeNative.execute({
      action: 'sendToTermsSubscription',
      metadata: { pdfUrl: campaignData?.termsUrl },
    })
  }

  async function submitCampaign() {
    await registerCampaign(`app-pf/foods/orders/v1/events?`, undefined, {
      event: sort,
    })
  }

  const handleButton = async () => {
    if (campaignData?.isDeepLink) {
      if (!campaignData.link.includes('bancointer://')) {
        await bridgeNative.execute({
          action: campaignData.link,
          metadata: { productId: 'DUO_GOURMET' },
        })
      } else {
        await interWbNavigate.openDeepLink(campaignData.link)
      }
    }

    if (!campaignData?.isDeepLink && campaignData?.link) {
      if (campaignData?.link.includes('https://')) {
        submitCampaign()
        interWbNavigate.openDeepLink(campaignData?.link)
        return
      }

      navigate(campaignData?.link)
    }
  }

  return (
    <>
      {loadShimmer && (
        <div style={{ padding: '24px' }}>
          <Skeleton width="100%" height="2rem" />
          <Skeleton width="100%" height="25rem" />
          <Skeleton width="100%" height="2rem" />
          <Skeleton width="100%" height="3rem" />
          <br />
          <br />
          <Skeleton width="20%" height="2rem" />
          <Skeleton width="100%" height="1rem" />
          <Skeleton width="100%" height="1rem" />
          <br />
          <br />
          <Skeleton width="100%" height="3rem" />
        </div>
      )}

      {!loadShimmer && (
        <>
          <AppBar name={campaignData?.screenName} />
          <Container>
            <UpperContent>
              <img
                src={campaignData?.image}
                alt={campaignData?.imageAlt}
                style={{ width: '100%', paddingTop: '64px' }}
              />
              <Wrap style={{ paddingBottom: 0 }}>
                <Text variant="headline-big" style={{ fontWeight: 700, paddingRight: 24 }}>
                  {campaignData?.title}
                </Text>

                {campaignData?.event && campaignData?.event.length > 0 && (
                  <div style={{ marginTop: '16px' }}>
                    <ListIconText width={16} height={16} list={campaignData?.event} />
                  </div>
                )}

                <Text variant="body-3" style={{ marginTop: '8px' }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: campaignData?.description as string,
                    }}
                  />
                </Text>
              </Wrap>

              <Divider
                borderWidth={8}
                style={{ width: '100%', marginTop: '32px', marginBottom: '32px' }}
                color="var(--gray100)"
              />

              <Wrap style={{ paddingTop: 0, paddingBottom: '104px' }}>
                {campaignData?.rules && campaignData?.rules?.length > 0 && (
                  <div>
                    <Text
                      variant="body-3"
                      bold
                      colorWeight={500}
                      style={{ marginBottom: '16px', display: 'block' }}
                    >
                      {campaignData?.rulesTitle}
                    </Text>
                    <ListIconText width={16} height={16} list={campaignData?.rules} />
                  </div>
                )}

                {campaignData?.termsUrl && (
                  <Button
                    variant="link"
                    style={{ padding: '6px 0' }}
                    data-testid="terms-button"
                    onClick={() => handleToTerms()}
                  >
                    {t('campaignDetail.seeCampaignRegulation')}
                  </Button>
                )}
              </Wrap>
            </UpperContent>

            <BottomContent>
              <Button onClick={handleButton} data-testid="handle-button" style={{ width: '100%' }}>
                {campaignData?.button}
              </Button>
            </BottomContent>
          </Container>
        </>
      )}
    </>
  )
}

export default CampaignDetail
