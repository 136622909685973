import React, { useState } from 'react'

import { applyMask } from 'utils/inputUtils'
import { onlyDigits } from 'utils/Utils'
import { BottomSheet } from 'components/BottomSheet'
import { useTranslation } from 'react-i18next'

import HelpIcon from '@interco/icons/orangeds/XL/help'
import ForkKnifeIcon from '@interco/icons/orangeds/SM/fork-knife'
import { Text } from '@interco/inter-ui/components/Text'

import * as S from './TotalSaved.styles'

interface TotalSavedProps {
  value: string
  showHelpIcon?: boolean
  analyticsTag: () => void
}

export const TotalSaved = ({
  value,
  showHelpIcon = true,

  analyticsTag,
}: TotalSavedProps) => {
  const [showBottomSheetPrice, setShowBottomSheetPrice] = useState<boolean>(false)
  const { t } = useTranslation()

  const bsContent = (
    <div style={{ marginTop: '12px' }}>
      <Text variant="body-3">{t('restaurantsRecords.totalSavedMessage')}</Text>
    </div>
  )

  const handleBSClick = () => {
    analyticsTag()
    setShowBottomSheetPrice(true)
  }

  const totalSavedConvert = Number(value.replace('R$', '').replace('.', '').replace(',', '.'))

  const handleBSClose = () => setShowBottomSheetPrice(false)
  if (value && totalSavedConvert > 0) {
    return (
      <>
        <S.Wrapper>
          <Text variant="headline-h3" colorWeight={400} as="p">
            {t('restaurantsRecords.totalSaved')}
            {showHelpIcon && (
              <HelpIcon
                data-testid="help-icon"
                width={16}
                height={16}
                color="var(--primary500)"
                onClick={handleBSClick}
              />
            )}
          </Text>
          <Text variant="headline-h1" semiBold className="saved-value" as="p">
            {applyMask(onlyDigits(value), 'currency')}
          </Text>
        </S.Wrapper>
        <BottomSheet
          showBottomSheet={showBottomSheetPrice}
          onDismiss={handleBSClose}
          headerLabel={t('restaurantsRecords.estimatedValue')}
          headerBtLabel={t('utils.closeBt')}
          headerBtHandler={handleBSClose}
          footerBtLabel={t('utils.understoodBt')}
          footerBtHandler={handleBSClose}
          bsContent={bsContent}
        />
      </>
    )
  }

  return (
    <S.Wrapper>
      <ForkKnifeIcon width={32} height={32} color="var(--primary500)" />
      <Text variant="headline-h2" semiBold>
        {t('utils.duoGourmet')}
      </Text>
      <Text variant="caption-1"> {t('restaurantsRecords.useAndStartSaving')}</Text>
    </S.Wrapper>
  )
}

export default TotalSaved
