import React from 'react'

import { AppContainer } from 'App.styles'
import AppBar from 'components/AppBar'
import ImgSuccess from 'assets/imgs/blackcard/success.svg'
import { useTranslation } from 'react-i18next'

import { interWbNavigate } from '@interco/inter-webview-bridge'
import { Alert } from '@interco/inter-ui/components/Alert'
import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'
import { useNavigate } from '@reach/router'

import { ImgContent, TextContent, ButtonContent, AlertStyle } from './BlackCard.styles'
import * as T from './BlackCard.tags'

const RequestSuccess = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleGoBack = async () => {
    T.handleTagHome()
    navigate('/')
  }

  const handleGoCards = async () => {
    try {
      T.handleTagCards()
      await interWbNavigate.openDeepLink('bancointer://cartoes')
    } catch (error) {
      window.console.error(error)
    }
  }

  return (
    <>
      <AppBar
        name={t('blackcard.screenTitle')}
        backTag={() => T.handleTagHeaderStep({ ref_figma: '11', step: '3' })}
      />
      <AppContainer
        fullHeight
        style={{
          paddingTop: '5rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <ImgContent>
            <img src={ImgSuccess} alt="" />
          </ImgContent>
          <TextContent>
            <Text
              variant="headline-h1"
              semiBold
              style={{ marginBottom: 10 }}
              className="center-text"
            >
              {t('blackcard.blackcardRequested')}
            </Text>
            <Text variant="body-3" className="center-text">
              {t('blackcard.cardRequestedDescription')}
            </Text>
            <AlertStyle>
              <Alert type="grayscale">
                <Text variant="caption-1">{t('blackcard.seeRequestDetails')}</Text>
              </Alert>
            </AlertStyle>
          </TextContent>
        </div>
        <ButtonContent>
          <div className="bt-spacer">
            <Button data-testid="back-button" className="bt-style" onClick={handleGoBack}>
              {t('blackcard.goBackToDuoGourmet')}
            </Button>
          </div>
          <Button
            data-testid="card-button"
            variant="secondary"
            className="bt-style"
            onClick={handleGoCards}
          >
            {t('blackcard.goToCards')}
          </Button>
        </ButtonContent>
      </AppContainer>
    </>
  )
}

export default RequestSuccess
