import React from 'react'

import ImgError from 'assets/imgs/blackcard/error.svg'
import ImgPending from 'assets/imgs/blackcard/pending.svg'
import { AppContainer } from 'App.styles'
import AppBar from 'components/AppBar'
import { useTranslation } from 'react-i18next'

import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'
import { useNavigate } from '@reach/router'

import { ButtonContent, PersonasImgContent } from './PendingPayment.styles'
import * as T from './PendingPayment.tags'

interface PendingPaymentProps {
  requestResponse: boolean
  setRetryPaymentPage: (showPage: boolean) => void
}

const PendingPayment = ({ requestResponse, setRetryPaymentPage }: PendingPaymentProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleGoHome = () => {
    T.handleTagBackHome()
    setRetryPaymentPage(false)
    navigate('/')
  }

  const handleRetry = () => {
    T.handleTagRetryBt()
    setRetryPaymentPage(false)
  }

  if (requestResponse) {
    T.handleTagRetryPaymentSuccess()
    return (
      <>
        <AppBar name="Duo Gourmet" goBackNative />
        <AppContainer style={{ textAlign: 'center', paddingTop: 100 }}>
          <PersonasImgContent>
            <img src={ImgPending} alt="" />
          </PersonasImgContent>
          <div>
            <Text variant="headline-h1" semiBold>
              {t('subscription.processingSubscription')}
            </Text>
          </div>
          <div style={{ marginTop: '0.5rem' }}>
            <Text variant="body-3">{t('subscription.processingSubscriptionMessage')}</Text>
          </div>
        </AppContainer>
        <ButtonContent>
          <Button data-testid="onboarding-button" onClick={handleGoHome} style={{ width: '100%' }}>
            {t('subscription.backHome')}
          </Button>
        </ButtonContent>
      </>
    )
  }

  T.handleTagRetryPaymentError()
  return (
    <>
      <AppBar name={t('utils.duoGourmet')} goBackNative />
      <AppContainer style={{ textAlign: 'center', paddingTop: 100 }}>
        <PersonasImgContent>
          <img src={ImgError} alt="" />
        </PersonasImgContent>
        <div>
          <Text variant="headline-h1" semiBold>
            {t('subscription.subscriptionError')}
          </Text>
        </div>
        <div style={{ marginTop: '0.5rem' }}>
          <Text variant="body-3">{t('subscription.subscriptionErrorMessage')}</Text>
        </div>
      </AppContainer>
      <ButtonContent>
        <Button data-testid="onboarding-button" onClick={handleRetry} style={{ width: '100%' }}>
          {t('subscription.tryAgain')}
        </Button>
      </ButtonContent>
    </>
  )
}

export default PendingPayment
