import React, { useEffect } from 'react'

import queryString from 'query-string'
import Error from 'components/Error'
import useAsync from 'hooks/UseAsync'
import AppBar from 'components/AppBar'
import { HomeResponse } from 'types/Restaurant'
import RestaurantCard from 'components/RestaurantCard/RestaurantCard'
import Divider from 'components/Divider'
import { FixButton } from 'components/FixButton'
import { useFilters } from 'contexts/FiltersContext'
import { ETrackEvent } from 'enums/ETrackEvent'
import { ECurrentMainTab } from 'enums/EContexts'
import { useTranslation } from 'react-i18next'

import { RouteComponentProps, useNavigate, useParams } from '@reach/router'
import { Text } from '@interco/inter-ui/components/Text'

import SelectionsCollectionShimmer from './SelectionsCollectionShimmer'
import { SelectionsProps } from '../Home/Home.types'
import * as S from './SelectionsCollection.styles'
import { handleTagFullList } from '../Home/Home.tags'

const SelectionsCollection = ({ location }: RouteComponentProps) => {
  const params = useParams<{ selectionId: string }>()
  const { t } = useTranslation()

  const [selectionResponse, getSelectionResponse] = useAsync<HomeResponse>('get')

  const { selectionsTitle, city } = location?.state as SelectionsProps
  const { queryParam } = useFilters()
  const navigate = useNavigate()

  const requestProps = {
    offset: 0,
    size: 40,
    specialSelectionId: params.selectionId,
    city,
    latitude: '',
    longitude: '',
  }

  const fetchSelectionResponse = async () => {
    if (queryParam?.latitude && queryParam?.longitude) {
      requestProps.latitude = queryParam.latitude
      requestProps.longitude = queryParam.longitude
    }
    await getSelectionResponse(
      `${process.env.REACT_APP_API}/listing?${queryString.stringify({ ...requestProps })}`,
    ).catch(window.console.log)
  }

  const handleClick = () => {
    handleTagFullList({ section: selectionResponse.data?.title ?? '' })
    navigate(`/home/${ECurrentMainTab.restaurants}`)
  }

  useEffect(() => {
    fetchSelectionResponse()
  }, [])

  if (selectionResponse.isError) {
    return (
      <S.Wrapper>
        <AppBar name={t('utils.duoGourmet')} goBackNative />
        <Error goBackNative className="errorStyle" />
      </S.Wrapper>
    )
  }

  if (selectionResponse.isSuccess) {
    return (
      <S.Wrapper>
        <AppBar name={t('utils.duoGourmet')} />
        <S.Content>
          <Text variant="headline-h3" semiBold className="titleStyle">
            {selectionsTitle || selectionResponse.data?.title}
          </Text>
          <S.SelectionsList>
            {selectionResponse.data?.restaurants.map((restaurant) => (
              <RestaurantCard
                key={`card_${restaurant.id}}`}
                restaurant={restaurant}
                flow={ETrackEvent.F_HOME_SUB}
                screen={ETrackEvent.S_COLLECTION_LIST}
              />
            ))}
          </S.SelectionsList>
        </S.Content>
        <Divider color="var(--gray100)" style={{ margin: '0' }} />
        <FixButton
          label={t('recommendationsCollection.seeFullList')}
          width="100%"
          onClick={handleClick}
        />
      </S.Wrapper>
    )
  }

  return (
    <S.Wrapper>
      <AppBar name={t('utils.duoGourmet')} />
      <SelectionsCollectionShimmer />
    </S.Wrapper>
  )
}

export default SelectionsCollection
