import React from 'react'

import { BottomSheet } from 'components/BottomSheet'
import { useTranslation } from 'react-i18next'

import { Text } from '@interco/inter-ui/components/Text'

interface TooltipBsProps {
  showBottomSheet: boolean
  setShowBottomSheet: (value: boolean) => void
  bottomSheetText?: string
}

const TooltipBs = ({ showBottomSheet, setShowBottomSheet, bottomSheetText }: TooltipBsProps) => {
  const bsContent = (
    <div style={{ marginTop: '12px' }}>
      <Text variant="body-3">{bottomSheetText}</Text>
    </div>
  )

  const handleBSClose = () => setShowBottomSheet(false)
  const { t } = useTranslation()

  return (
    <BottomSheet
      showBottomSheet={showBottomSheet}
      onDismiss={handleBSClose}
      headerLabel={t('restaurantsDetails.benefitUnavailable')}
      headerBtLabel={t('utils.closeBt')}
      headerBtHandler={handleBSClose}
      footerBtLabel={t('utils.understoodBt')}
      footerBtHandler={handleBSClose}
      bsContent={bsContent}
    />
  )
}

export default TooltipBs
