import React, { useState, useEffect } from 'react'

import AppBar from 'components/AppBar'
import { AppContainer } from 'App.styles'
import useAsync from 'hooks/UseAsync'
import { ExperienceRatingPost, ExperienceUseEvents } from 'types/Experiences'
import { FixButton } from 'components/FixButton'
import { useTranslation } from 'react-i18next'

import StarFillIcon from '@interco/icons/orangeds/XL/star-fill'
import StarIcon from '@interco/icons/orangeds/XL/star'
import { RouteComponentProps } from '@reach/router'
import { Text } from '@interco/inter-ui/components/Text'
import { Textarea } from '@interco/inter-ui/components/Textarea'

import RatingResponse from './RatingResponse'
import * as S from './ExperiencesRating.styles'

const ExperiencesRating = ({ location }: RouteComponentProps) => {
  const utilization = location?.state as ExperienceUseEvents
  const iconSize = { width: 40, height: 40 }
  const { t } = useTranslation()

  const [score, setScore] = useState(0)
  const [comment, setComment] = useState('')
  const [commentCounter, setCommentCounter] = useState(0)
  const [disableButton, setDisableButton] = useState(true)
  const [rating, postRating] = useAsync<ExperienceRatingPost>('post')

  const handleSubmit = async () => {
    try {
      await postRating(`app-pf/foods/orders/v1/useEvents/experiences/rate`, {
        sequential: utilization.code,
        useDate: utilization.useDate,
        subscription: utilization.subscriptionId,
        experienceId: utilization.experienceId,
        rating: {
          value: score,
          comment,
        },
      })
    } catch (error) {
      window.console.error(`error: ${error}`)
    }
  }

  const onChangeComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value)
  }

  useEffect(() => {
    setCommentCounter(comment.length)
  }, [comment])

  useEffect(() => {
    if (score) {
      setDisableButton(false)
    }
  }, [score])

  if (rating.isError) {
    return <RatingResponse success={false} />
  }

  if (rating.isSuccess) {
    return <RatingResponse success />
  }

  return (
    <>
      <AppBar name={t('experiencesRating.review')} />
      <AppContainer fullHeight style={{ paddingTop: 60 }}>
        <S.HeaderStyled>
          <Text variant="body-3">{t('experiencesRating.tellUsExperience')}</Text>
          <Text variant="headline-h3" semiBold as="p">
            {utilization.experienceTitle}
          </Text>
        </S.HeaderStyled>
        <S.ReviewContainer>
          <S.StarRatingStyled>
            <S.StarsStyled>
              {[...Array(5)].map((_, index) => {
                index += 1
                return (
                  <button type="button" key={`score-${0 + index}`} onClick={() => setScore(index)}>
                    {index > score ? (
                      <StarIcon {...iconSize} color="var(--gray300)" />
                    ) : (
                      <StarFillIcon {...iconSize} color="var(--alert500)" />
                    )}
                  </button>
                )
              })}
            </S.StarsStyled>
          </S.StarRatingStyled>
          <Textarea
            id="comment"
            label={t('experiencesRating.leaveYourComment')}
            rows={6}
            maxLength={500}
            onChange={onChangeComment}
          />
          <S.CommentCounter>{commentCounter}/500</S.CommentCounter>
          <FixButton
            disabled={disableButton}
            onClick={handleSubmit}
            label={t('experiencesRating.sendReview')}
          />
        </S.ReviewContainer>
      </AppContainer>
    </>
  )
}

export default ExperiencesRating
