import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import translationPT from '../assets/locales/pt/translationPT.json'
import translationEN from '../assets/locales/en/translationEN.json'

const resources = {
  pt: {
    translation: translationPT,
  },
  en: {
    translation: translationEN,
  },
}

const duoGourmetInstance = i18n.createInstance()

duoGourmetInstance.use(initReactI18next).init({
  resources,
  debug: true,
  fallbackLng: 'pt',
  react: {
    useSuspense: true,
  },
  interpolation: {
    escapeValue: false,
  },
})

export default duoGourmetInstance
