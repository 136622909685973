import React, { useContext } from 'react'

import { AppContainer } from 'App.styles'
import AppBar from 'components/AppBar'
import BlackcardContext from 'contexts/BlackcardContext'
import ImgBlack from 'assets/imgs/blackcard/onboarding.png'
import { useTranslation } from 'react-i18next'

import AirportIcon from '@interco/icons/orangeds/XL/airport'
import SegurosIcon from '@interco/icons/orangeds/XL/seguros'
import ConciergeIcon from '@interco/icons/orangeds/XL/concierge'
import OutboundIcon from '@interco/icons/orangeds/XL/outbound'
import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'
import ChevronRightIcon from '@interco/icons/orangeds/XL/chevron-right'

import { AdvantagesContent, ImgContent, TextContent, ButtonContent } from './BlackCard.styles'
import * as T from './BlackCard.tags'

const Onboarding = () => {
  const { goToNext } = useContext(BlackcardContext)
  const { t } = useTranslation()

  const handleNext = async () => {
    T.handleTagNext()
    goToNext()
  }

  const iconProps = { color: 'var(--gray500)', width: 20, height: 20 }

  return (
    <>
      <AppBar name={t('blackcard.screenTitle')} backTag={T.handleTagHeaderOnboarding} />
      <AppContainer
        fullHeight
        style={{
          paddingTop: '1.5rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <ImgContent>
            <img src={ImgBlack} alt="" style={{ width: '100%' }} />
          </ImgContent>
          <TextContent>
            <Text variant="headline-h1" semiBold style={{ marginBottom: 10 }}>
              {t('blackcard.canRequestBlackcard')}
            </Text>
            <Text variant="body-3">{t('blackcard.blackcardDescription')}</Text>
            <AdvantagesContent>
              <div className="adv-item">
                <AirportIcon {...iconProps} />
                <Text variant="body-3" bold colorWeight={500} className="adv-text">
                  {t('blackcard.airportVipRoom')}
                </Text>
              </div>
              <div className="adv-item">
                <SegurosIcon {...iconProps} />
                <Text variant="body-3" bold colorWeight={500} className="adv-text">
                  {t('blackcard.theftProtection')}
                </Text>
              </div>
              <div className="adv-item">
                <ConciergeIcon {...iconProps} />
                <Text variant="body-3" bold colorWeight={500} className="adv-text">
                  {t('blackcard.mastercardAirportConcierge')}
                </Text>
              </div>
              <div className="adv-item">
                <OutboundIcon {...iconProps} />
                <Text variant="body-3" bold colorWeight={500} className="adv-text">
                  {t('blackcard.travelRewardsAndMore')}
                </Text>
              </div>
            </AdvantagesContent>
          </TextContent>
        </div>
        <ButtonContent>
          <Button
            className="bt-style"
            data-testid="continue-button"
            onClick={handleNext}
            icon={<ChevronRightIcon height={24} width={24} color="var(--neutral-theme)" />}
          >
            {t('utils.continueBt')}
          </Button>
        </ButtonContent>
      </AppContainer>
    </>
  )
}

export default Onboarding
