import React, { useState } from 'react'

import AppBar from 'components/AppBar'
import { useTranslation } from 'react-i18next'

import { RouteComponentProps } from '@reach/router'
import { TabDefinition, TabPager } from '@interco/inter-ui/components/TabPager'

import * as S from './ExperiencesRecords.styles'
import * as T from './ExperiencesRecords.tags'
import ActiveRecords from './ActiveRecords'
import EndedRecords from './EndedRecords'

const ExperiencesRecords = (_: RouteComponentProps) => {
  const { t } = useTranslation()
  const [currentTab, setCurrentTab] = useState<string | number>('active')

  const tabDefinitions: TabDefinition[] = [
    {
      tabId: 'active',
      label: t('experiencesRecords.active'),
      content: <ActiveRecords />,
    },
    {
      tabId: 'ended',
      label: t('experiencesRecords.ended'),
      content: <EndedRecords />,
    },
  ]

  const handleTabChange = (tabId: string | number) => {
    setCurrentTab(tabId)
  }

  return (
    <S.Wrapper>
      <AppBar name={t('experiencesRecords.myExperiences')} backTag={T.handleHeaderGoBack} />
      <S.Content>
        <TabPager
          currentTabId={currentTab}
          onTabSelect={handleTabChange}
          tabDefinitions={tabDefinitions}
        />
      </S.Content>
    </S.Wrapper>
  )
}

export default ExperiencesRecords
