import React from 'react'

import InvalidAssignatureBs from 'components/InvalidAssignatureBs'
import { useBSState } from 'contexts/FiltersBottomsheetContext'
import { FixButton } from 'components/FixButton'
import { bridgeNative } from 'App'
import { useConfigs } from 'contexts/ConfigsContext'
import { IBanner } from 'types/Campaign'
import { getParamsFromURL } from 'utils/TagsUtils'
import { handleTagSubscribe } from 'pages/Home/RestaurantsTab/RestaurantsTab.tags'
import { useTranslation } from 'react-i18next'

interface SubscribeBtProps {
  banners: IBanner[]
  pessoaFisica: boolean
  canSubscribe: {
    canSubscribe: boolean
    text: string
    button: string
  }
  subscriptionButton: string
}

const SubscribeBt = ({
  banners,
  pessoaFisica,
  canSubscribe,
  subscriptionButton,
}: SubscribeBtProps) => {
  const { bsStateParam, setBsStateParam } = useBSState()
  const { configs } = useConfigs()
  const { t } = useTranslation()

  const getBlackBanners = () =>
    banners.filter((banner) =>
      banner.description?.toLowerCase().includes(t('subscription.withDuoGourmetSubscription')),
    )

  const handleSubscription = async () => {
    const { utmParams, adjParams } = getParamsFromURL()
    try {
      handleTagSubscribe({ ...utmParams, ...adjParams })
      if (!pessoaFisica) {
        setBsStateParam({ ...bsStateParam, showBottomSheetInvalidAssignature: true })
      } else {
        await bridgeNative.execute({
          action: 'sendToSubscription',
          metadata: { productId: 'DUO_GOURMET' },
        })
      }
    } catch (error) {
      window.console.log(error)
    }
  }

  return (
    <>
      {!canSubscribe?.canSubscribe ? (
        <>
          <FixButton
            label={subscriptionButton}
            width="100%"
            onClick={() => {
              setBsStateParam({ ...bsStateParam, showBottomSheetInvalidAssignature: true })
            }}
          />
          <InvalidAssignatureBs
            text={canSubscribe?.text}
            button={canSubscribe?.button}
            canSubscribe={canSubscribe}
            blackCardBanners={getBlackBanners()}
            isDuoGourmetLegacyUser={configs.subscriber.duoGourmetLegacyCustomer}
          />
        </>
      ) : (
        <FixButton onClick={handleSubscription} label={subscriptionButton} width="100%" />
      )}
    </>
  )
}

export default SubscribeBt
