import React from 'react'

import { DebouncedState } from 'use-debounce/lib/useDebouncedCallback'
import { useTranslation } from 'react-i18next'

import SearchIcon from '@interco/icons/orangeds/XL/search'
import { Input } from '@interco/inter-ui/components/Input'

import * as S from './InputSearch.styles'

interface InputSearchProps {
  searchText: string
  setSearchText: (searchText: string) => void
  search?: DebouncedState<(value: string) => Promise<void>>
  className?: string
}

const InputSearch = ({ searchText, setSearchText, search, className }: InputSearchProps) => {
  const iconSize = { width: 20, height: 20 }
  const { t } = useTranslation()

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (search) {
      search(e.target.value || '')
    }
    setSearchText(e.target.value || '')
  }

  return (
    <S.InputSearch className={className}>
      <Input
        iconLeft={<SearchIcon {...iconSize} color="var(--primary500)" className="iconStyle" />}
        value={searchText}
        type="search"
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'Enter') {
            e.currentTarget.blur()
          }
        }}
        placeholder={t('utils.search')}
        className="inputStyle"
        onChange={onChangeHandler}
      />
    </S.InputSearch>
  )
}

export default InputSearch
