import React, { useEffect, useState } from 'react'

import { AppContainer } from 'App.styles'
import AppBar from 'components/AppBar'
import ImgError from 'assets/imgs/blackcard/error.svg'
import ImgPending from 'assets/imgs/blackcard/pending.svg'
import { useTranslation } from 'react-i18next'

import { interWbNavigate } from '@interco/inter-webview-bridge'
import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'

import { ButtonContent, ImgContent, TextContent } from './BlackCard.styles'

interface IStatus {
  title: string
  image: string
  description: string
  btLabel: string
  btHandler: () => void
}

interface RequestStatusProps {
  issue: number
}

const RequestStatus = ({ issue }: RequestStatusProps) => {
  const [status, setStatus] = useState({} as IStatus)
  const { t } = useTranslation()

  const statusContent = [
    {
      title: t('blackcard.alreadyHaveBlackcard'),
      image: ImgError,
      description: t('blackcard.advantagesTitle'),
      btLabel: t('blackcard.goToCards'),
      btHandler: async () => {
        try {
          await interWbNavigate.openDeepLink('bancointer://relacionamentoCliente')
        } catch (error) {
          window.console.error(error)
        }
      },
    },
    {
      title: t('blackcard.noActiveInterCard'),
      image: ImgError,
      description: t('blackcard.activateInterCard'),
      btLabel: t('blackcard.goToCards'),
      btHandler: async () => {
        try {
          await interWbNavigate.openDeepLink('bancointer://cartoes')
        } catch (error) {
          window.console.error(error)
        }
      },
    },
    {
      title: t('blackcard.blackcardRequested'),
      image: ImgPending,
      description: t('blackcard.cardDeliveryAddress'),
      btLabel: t('blackcard.trackCardBt'),
      btHandler: async () => {
        try {
          await interWbNavigate.openDeepLink('bancointer://cartoes')
        } catch (error) {
          window.console.error(error)
        }
      },
    },
  ]

  useEffect(() => {
    setStatus(statusContent[issue])
  }, [])

  return (
    <>
      <AppBar name={t('blackcard.screenTitle')} />
      <AppContainer
        fullHeight
        style={{
          paddingTop: '5rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <ImgContent>
            <img src={status.image} alt="" />
          </ImgContent>
          <TextContent>
            <Text
              variant="headline-h1"
              semiBold
              style={{ marginBottom: 10 }}
              className="center-text"
            >
              {status.title}
            </Text>
            <Text variant="body-3" className="center-text">
              {status.description}
            </Text>
          </TextContent>
        </div>
        <ButtonContent>
          <Button className="bt-style" onClick={status.btHandler}>
            {status.btLabel}
          </Button>
        </ButtonContent>
      </AppContainer>
    </>
  )
}

export default RequestStatus
