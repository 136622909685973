import React from 'react'

import ListItem from 'components/ListItem'
import Divider from 'components/Divider'
import AppBar from 'components/AppBar'
import useAsync from 'hooks/UseAsync'
import { MenuItem, MenuResponse } from 'types/MenuUser'
import TotalSaved from 'components/TotalSaved'
import { useConfigs } from 'contexts/ConfigsContext'
import Error from 'components/Error'
import { useTranslation } from 'react-i18next'

import { RouteComponentProps, useNavigate } from '@reach/router'
import { interWbNavigate } from '@interco/inter-webview-bridge'

import * as T from './MenuUser.tags'
import * as S from './MenuUser.styles'
import MenuUsuarioShimmer from './MenuUserShimmer'

const MenuUsuario = (_: RouteComponentProps) => {
  const { t } = useTranslation()
  const screenName = t('menuUser.screenName')

  const navigate = useNavigate()
  const { configs } = useConfigs()

  const [menuResponse, getMenuResponse] = useAsync<MenuResponse>('get')

  React.useEffect(() => {
    fetchMenu()
  }, [])

  const fetchMenu = React.useCallback(async () => {
    await getMenuResponse(`${process.env.REACT_APP_API}/menu`).catch(window.console.error)
  }, [])

  const handleOnClick = async (item: MenuItem) => {
    T.handleTagItems({
      plan_name: configs.subscriber.planName,
      plan_value: configs.subscriber.planValue,
      label: item.label,
    })

    if (item.hasDeeplink) {
      await interWbNavigate.openDeepLink(item.path)
    } else {
      navigate(item.path)
    }
  }

  if (menuResponse.isError) {
    return <Error goBackNative className="errorStyle" />
  }

  if (menuResponse.isPending) {
    return <MenuUsuarioShimmer />
  }

  if (menuResponse.isSuccess) {
    return (
      <S.Wrapper>
        <AppBar name={screenName} backTag={T.handleTagHeader} />
        <S.Content>
          <TotalSaved
            value={menuResponse.data?.totalSaved}
            analyticsTag={() =>
              T.handleTagTotalSaved({
                plan_name: configs.subscriber.planName,
                plan_value: configs.subscriber.planValue,
              })
            }
          />

          {menuResponse.data.menuItems.map((item, idx) => (
            <div key={item.label}>
              <ListItem
                label={item.label}
                arrow
                data-testid={item.label}
                onClick={() => handleOnClick(item)}
              />
              {idx + 1 < menuResponse.data.menuItems.length && <Divider className="lineStyle" />}
            </div>
          ))}
        </S.Content>
      </S.Wrapper>
    )
  }

  return null
}

export default MenuUsuario
