import { Dispatch, SetStateAction } from 'react'

import { QueryParamRestaurant, useFilters } from 'contexts/FiltersContext'
import { useBSState } from 'contexts/FiltersBottomsheetContext'
import { useConfigs } from 'contexts/ConfigsContext'

import { Alert } from '@interco/inter-ui/components/Alert'
import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'

import { handleTagApply, handleTagMap } from './LocationFilterTags'
import { AlertStyle, FilterItem } from './LocationFilter.styles'
import { StateCitiesProps } from './LocationFilter.types'

type BsContentType = {
  type: 'home' | 'restaurant'
  onFilter: (newCity?: string) => Promise<void>
  fetchRestaurants: (newQueryParam?: QueryParamRestaurant) => void
  selectedState: StateCitiesProps
  setSelectedState: Dispatch<SetStateAction<StateCitiesProps>>
}

export function LocationBsContent(props: BsContentType) {
  const { selectedState, setSelectedState, fetchRestaurants, onFilter, type } = props
  const { queryParam } = useFilters()
  const { bsStateParam, setBsStateParam } = useBSState()
  const { configs } = useConfigs()
  const isRestaurant = type === 'restaurant'
  const getSelectedCity = () =>
    selectedState.cities.find((city) => city.selected) ?? {
      label: '',
      selected: false,
      value: '',
    }

  const handleChange = (index: number) => {
    const list = selectedState.cities.map((d, idx) => ({ ...d, selected: idx === index }))
    setSelectedState({ ...selectedState, cities: list })
  }
  const handleSubmit = async () => {
    handleTagApply({
      content_state: selectedState.label,
      content_city: getSelectedCity().label,
      plan_name: configs.subscriber.planName,
      plan_value: configs.subscriber.planValue,
      subscriber: `${configs.subscriber.duoGourmetInterCustomer}`,
    }).catch(window.console.error)
    onFilter()
  }

  const handleGoMap = () => {
    if (isRestaurant) {
      setBsStateParam({ ...bsStateParam, showBottomSheetState: false })
      handleTagMap({
        plan_name: configs.subscriber.planName,
        plan_value: configs.subscriber.planValue,
        subscriber: `${configs.subscriber.duoGourmetInterCustomer}`,
      })
      const newCity = selectedState.cities?.filter((d) => d.selected)[0]?.value

      fetchRestaurants({ ...queryParam, city: newCity })
      setBsStateParam({ ...bsStateParam, showBottomSheetLocation: false, showMapFilter: true })
    }
  }
  return (
    <>
      {selectedState?.cities && selectedState?.cities.length > 0 && (
        <div style={{ marginBottom: 16 }}>
          {selectedState?.cities.map((city, index) => (
            <FilterItem key={city.label}>
              <input
                className="input"
                type="checkbox"
                id={city.label}
                name={city.label}
                onChange={() => handleChange(index)}
                checked={city.selected}
              />
              <label className="label" htmlFor={city.label}>
                {city.label}
              </label>
            </FilterItem>
          ))}
        </div>
      )}
      {isRestaurant && (
        <AlertStyle style={{ marginBottom: 16 }}>
          <Alert type="grayscale">
            <Text variant="caption-1">
              Ao trocar de cidade os filtros selecionados anteriormente serão redefinidos.
            </Text>
          </Alert>
        </AlertStyle>
      )}
      <Button
        variant="primary"
        style={{ width: '100%', marginBottom: '16px' }}
        onClick={handleSubmit}
        disabled={!getSelectedCity()}
      >
        Aplicar
      </Button>
      {isRestaurant && (
        <Button
          variant="secondary"
          style={{ width: '100%' }}
          onClick={handleGoMap}
          disabled={!getSelectedCity()}
        >
          Abrir mapa
        </Button>
      )}
    </>
  )
}
