import React from 'react'

import i18n from 'utils/i18n'

import { Text } from '@interco/inter-ui/components/Text'
import { Loading as LoadingIcon } from '@interco/inter-ui/components/Loading'

import * as S from './Loading.styles'

const Loading = () => (
  <S.Wrapper>
    <Text variant="headline-h3" className="appbar-title" semiBold>
      {i18n.t('utils.duoGourmet')}
    </Text>
    <LoadingIcon width={40} height={40} color="var(--primary500)" />
  </S.Wrapper>
)

export default Loading
