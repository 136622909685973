import React, { useContext } from 'react'

import { AppContainer } from 'App.styles'
import AppBar from 'components/AppBar'
import BlackcardContext from 'contexts/BlackcardContext'
import ImgBlack from 'assets/imgs/blackcard/step-one.svg'
import { BottomSheet } from 'components/BottomSheet'
import { useTranslation } from 'react-i18next'

import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'
import ChevronRightIcon from '@interco/icons/orangeds/XL/chevron-right'

import { ImgContent, TextContent, ButtonContent } from './BlackCard.styles'
import * as T from './BlackCard.tags'

const StepOne = () => {
  const { goToNext } = useContext(BlackcardContext)
  const [showBottomSheet, setShowBottomSheet] = React.useState(false)
  const { t } = useTranslation()

  const handleNext = async () => {
    T.handleTagRequestStep({ ref_figma: '2', step: '1' })
    setShowBottomSheet(true)
  }

  const handleBSNext = () => {
    T.handleTagBSNext()
    setShowBottomSheet(false)
    goToNext()
  }

  const handleBSClose = () => {
    T.handleTagBSClose()
    setShowBottomSheet(false)
  }

  const bsContent = (
    <div style={{ marginTop: '12px' }}>
      <Text variant="body-3">{t('blackcard.confirmRequestDescription')}</Text>
    </div>
  )

  return (
    <>
      <AppBar
        name={t('blackcard.screenTitle')}
        backTag={() => T.handleTagHeaderStep({ ref_figma: '9', step: '1' })}
      />
      <AppContainer
        fullHeight
        style={{
          paddingTop: '3rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <ImgContent>
            <img src={ImgBlack} alt="" />
          </ImgContent>
          <TextContent>
            <Text
              variant="headline-h1"
              semiBold
              style={{ marginBottom: 10 }}
              className="center-text"
            >
              {t('blackcard.getYourBlackcard')}
            </Text>
            <Text variant="body-3" className="center-text">
              {t('blackcard.confirmRequestData')}
            </Text>
          </TextContent>
        </div>
        <ButtonContent>
          <Button
            className="bt-style"
            onClick={handleNext}
            icon={<ChevronRightIcon height={24} width={24} color="var(--neutral-theme)" />}
          >
            {t('blackcard.requestInterBlack')}
          </Button>
        </ButtonContent>
      </AppContainer>
      <BottomSheet
        showBottomSheet={showBottomSheet}
        onDismiss={handleBSClose}
        headerLabel={t('blackcard.registerDataReview')}
        headerBtLabel={t('utils.continueBt')}
        headerBtHandler={handleBSClose}
        footerBtLabel={t('utils.understoodBt')}
        footerBtHandler={handleBSNext}
        bsContent={bsContent}
      />
    </>
  )
}

export default StepOne
