import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  padding: 0px;
`

export const SearchInput = styled.div`
  > .inputStyle {
    width: 100%;
  }
`

export const StateItem = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray200);
`

export const FilterItem = styled.div`
  display: inline-block;

  .input {
    display: none;
  }

  .input + .label {
    float: right;
    margin: 8px 8px 0 0;
    padding: 5px 12px;
    font-size: 12px;
    line-height: 20px;
    color: var(--gray400);
    text-align: center;
    cursor: pointer;
    border: 1px solid var(--gray200);
    border-radius: 20px;
  }

  .input:checked + .label {
    background-image: none;
    background-color: var(--gray500);
    color: var(--gray100);
    border: 1px solid var(--gray500);
  }
`

export const BsBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 74vh;

  align-items: center;
  justify-content: center;
`

export const AlertStyle = styled.div`
  margin: 0 0 1rem 0;
`
