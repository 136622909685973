import React from 'react'

import ImgError from 'assets/imgs/blackcard/error.svg'
import useGoBack from 'hooks/useGoBack'
import i18n from 'utils/i18n'

import { Text } from '@interco/inter-ui/components/Text'
import { Button } from '@interco/inter-ui/components/Button'

import * as S from './Error.style'

interface ErrorProps extends React.HTMLAttributes<unknown> {
  title?: string
  description?: string
  goBackNative?: boolean
  className?: string
}

const Error = ({
  title = i18n.t('utils.errorMessage'),
  description = i18n.t('utils.errorDescription'),
  goBackNative,
  className,
}: ErrorProps) => {
  const goBack = useGoBack({ goBackNative })

  return (
    <S.Wrapper className={className}>
      <S.Content>
        <img src={ImgError} alt="" style={{ marginBottom: 24 }} />
        <Text variant="headline-h1" semiBold className="bold d-block">
          {title}
        </Text>
        <Text variant="body-3" className="d-block" style={{ marginTop: 8 }}>
          {description}
        </Text>
      </S.Content>
      <Button variant="primary" onClick={goBack}>
        {i18n.t('utils.goBack')}
      </Button>
    </S.Wrapper>
  )
}

export default Error
