import Divider from 'components/Divider'
import i18n from 'utils/i18n'

import { Grid, GridItem } from '@interco/inter-ui/components/Grid'
import { Text } from '@interco/inter-ui/components/Text'

import { ScheduleChartProps, ScheduleLineProps, WEEK } from './OperationCalendar'
import { MealTimeItem, ScheduleContainer } from './OperationCalendar.styles'

const ScheduleChart = ({ modality, schedule }: ScheduleChartProps) => (
  <>
    <Text variant="headline-h3" semiBold>
      {i18n.t('calendar.availability')}: {modality}
    </Text>
    <ScheduleContainer>
      <ScheduleHeader />
      <Grid
        templateRows="repeat(7, 1fr)"
        templateColumns="repeat(3, 1fr)"
        gap="5px"
        style={{ marginTop: '0.3rem' }}
      >
        {schedule.map((item, index) => {
          if (index === 0) {
            return (
              <ScheduleLine
                key={item.dayOfWeek}
                dayOfWeek={item.dayOfWeek}
                lunchSchedule={item.lunchSchedule}
                dinnerSchedule={item.dinnerSchedule}
                isToday
              />
            )
          }

          return (
            <ScheduleLine
              key={item.dayOfWeek}
              dayOfWeek={item.dayOfWeek}
              lunchSchedule={item.lunchSchedule}
              dinnerSchedule={item.dinnerSchedule}
            />
          )
        })}
      </Grid>
    </ScheduleContainer>
  </>
)

const ScheduleHeader = () => (
  <Grid templateRows="repeat(1, 1fr)" templateColumns="repeat(3, 1fr)" gap="5px">
    <GridItem>
      <Text variant="caption-1" bold>
        {i18n.t('calendar.day')}
      </Text>
    </GridItem>
    <GridItem>
      <MealTimeItem>
        <Text variant="caption-1" bold>
          {i18n.t('calendar.lunch')}
        </Text>
      </MealTimeItem>
    </GridItem>
    <GridItem>
      <MealTimeItem>
        <Text variant="caption-1" bold>
          {i18n.t('calendar.dinner')}
        </Text>
      </MealTimeItem>
    </GridItem>
    <GridItem column="span 3">
      <Divider style={{ margin: 0 }} />
    </GridItem>
  </Grid>
)

const ScheduleLine = ({ dayOfWeek, lunchSchedule, dinnerSchedule, isToday }: ScheduleLineProps) => {
  const enumDay = WEEK[dayOfWeek as keyof typeof WEEK]

  return (
    <>
      <GridItem>
        <Text variant="caption-1" bold={isToday} colorWeight={500}>
          {isToday ? `Hoje, ${enumDay.toLocaleLowerCase()}` : enumDay}
        </Text>
      </GridItem>
      <GridItem>
        <MealTimeItem>
          <Text variant="caption-1" bold={isToday} colorWeight={500}>
            {lunchSchedule}
          </Text>
        </MealTimeItem>
      </GridItem>
      <GridItem>
        <MealTimeItem>
          <Text variant="caption-1" bold={isToday} colorWeight={500}>
            {dinnerSchedule}
          </Text>
        </MealTimeItem>
      </GridItem>
    </>
  )
}

export default ScheduleChart
